import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SlArrowRight } from "react-icons/sl";
import '../brokers.css';
// import { GiCheckMark } from "react-icons/gi";
// import { FaXmark } from "react-icons/fa6";
// import { FaCircleXmark } from "react-icons/fa6";
// import { HiXMark } from "react-icons/hi2";
import { hostInfo , token } from '../server';

const BrokerDetails = ({deviceInfo,navigation}) => {
    const Params = useParams();
    console.log("broker-details--------",Params["broker-details"]);
    const nevigate = useNavigate();

    const [brokerData, setBrokerData] = useState({});
    console.log(brokerData,"..................",Params["broker-details"]
      .replace(/-/g, " ") // Replace hyphens with spaces
      .toLowerCase() // Convert the entire string to lowercase
      .split(" ") // Split the string into an array of words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "))


      // Set the initial active plan to the first key in the Brokerage_Plan object
    const [activePlan, setActivePlan] = useState("");
    console.log(activePlan);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${hostInfo}/get_broker_details`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
              'Content-Type': 'application/json', // Set the Content-Type if necessary
            },
            body: JSON.stringify({ broker_name: Params["broker-details"]
              .replace(/-/g, " ") // Replace hyphens with spaces
              .toLowerCase() // Convert the entire string to lowercase
              .split(" ") // Split the string into an array of words
              .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
              .join(" ")})
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();
          // console.log(data)
          setBrokerData(data);
          
        } catch (error) {
          console.error('Error fetching data:', error);
          nevigate('/brokers');
          // Handle the error as needed
        }
      };
    
      fetchData();
  
    }, [Params["broker-details"]]);

  // const brokerData = {
  //   "About_Broker": "Kotak Securities Limited, a subsidiary of Kotak Mahindra Bank Limited, is one of the leading stockbrokers in India. The company offers a wide range of trading and investment services, including research and advisory services, portfolio management services, wealth management, and more. As of February 2024, Kotak Securities has an active client base of 1,179,674.",
  //   "Broker_Type": "Discount Broker",
  //   "Broker_Logo": "https://media.ipoji.com/broker/images/kotak-sec-logo.jpeg",
  //   "Broker_Name": "Kotak Securities",
  //   "Open_Demat_Account_Url":"https://www.kotaksecurities.com/landing-page/fintech-partnership/ipoji-zero-intraday-charges-demat-account/",
  //   "Product_Basket": [
  //     "Equity",
  //     "Commodity",
  //     "Currency",
  //     "Futures",
  //     "Options"
  //   ],
  //   "Brokerage_Plan": {
  //     "Trade Fee Youth Plan": {
  //       "Charges": {
  //         "Account Opening": "Free",
  //         "Account Maintenance": "₹600",
  //         "Plan Charges": "₹499",
  //         "Call Trade": "₹49"
  //       },
  //       "Brokerage": {
  //         "Equity Delivery": "Zero",
  //         "Equity Intraday": "Zero",
  //         "Equity Futures": "Zero",
  //         "Equity Options": "Zero",
  //         "Currency Futures": "Zero",
  //         "Currency Options": "Zero",
  //         "Commodity Futures": "Zero",
  //         "Commodity Options": "Zero"
  //       },
  //       "Margins": {
  //         "Equity Delivery": "5x As per exchange",
  //         "Equity Intraday": "5x As per exchange",
  //         "Equity Futures": "4x As per exchange",
  //         "Equity Options": "1x/5x Buy 1x, Sell 5x",
  //         "Currency Futures": "40x As per exchange",
  //         "Currency Options": "1x/40x Buy 1x, Sell 40x",
  //         "Commodity Futures": "8x As per exchange",
  //         "Commodity Options": "6x As per exchange"
  //       }
  //     },
  //     "Trade Free Plan": {
  //       "Charges": {
  //         "Account Opening": "Free",
  //         "Account Maintenance": "₹600",
  //         "Plan Charges": "₹499",
  //         "Call Trade": "₹49"
  //       },
  //       "Brokerage": {
  //         "Equity Delivery": "Zero",
  //         "Equity Intraday": "Zero",
  //         "Equity Futures": "Zero",
  //         "Equity Options": "Zero",
  //         "Currency Futures": "Zero",
  //         "Currency Options": "Zero",
  //         "Commodity Futures": "Zero",
  //         "Commodity Options": "Zero"
  //       },
  //       "Margins": {
  //         "Equity Delivery": "5x As per exchange",
  //         "Equity Intraday": "5x As per exchange",
  //         "Equity Futures": "4x As per exchange",
  //         "Equity Options": "1x/5x Buy 1x, Sell 5x",
  //         "Currency Futures": "40x As per exchange",
  //         "Currency Options": "1x/40x Buy 1x, Sell 40x",
  //         "Commodity Futures": "8x As per exchange",
  //         "Commodity Options": "6x As per exchange"
  //       }
  //     }
  //   },
  //   "Platforms_Offered": [
  //     "KEAT Pro X",
  //     "Kotak Stock Trader",
  //     "Kotak Securities website",
  //     "Kotak Neo"
  //   ],
  //   "Pros": [
  //     "Kotak Securities offers a wide range of investment options, including equity, debt instruments, mutual funds, and derivatives.",
  //     "The company provides daily alerts, market pointers, research reports, and stock recommendations to its customers.",
  //     "Customers have the option to make international investments, such as investing in US stocks.",
  //     "NRIs are also eligible to open an account with Kotak Securities.",
  //     "Call and email support are available to address customer queries."
  //   ],
  //   "Cons": [
  //     "Kotak Securities offers a wide range of investment options, including equity, debt instruments, mutual funds, and derivatives.",
  //     "The company provides daily alerts, market pointers, research reports, and stock recommendations to its customers.",
  //     "Customers have the option to make international investments, such as investing in US stocks.",
  //     "NRIs are also eligible to open an account with Kotak Securities.",
  //     "Call and email support are available to address customer queries."
  //   ],
  //   "Additional_Features": {
  //     "3 In 1 Account": "Yes",
  //     "Free Trading Calls": "Yes",
  //     "Free Research": "No",
  //     "SMS Alerts": "Yes",
  //     "Margin Funding": "Yes",
  //     "Margin Against Share": "Yes"
  //   },
  //   "Other_Investment_Options": {
  //     "Mutual Funds": "Yes",
  //     "IPO Platform": "Yes",
  //     "Bonds Debt": "No",
  //     "Exchange Traded Funds": "No",
  //     "Insurance": "Yes"
  //   }
  // };
  console.log(brokerData);
  
  // First, parse the JSON string with escaped characters
  const Brokerage_Plan =brokerData?.Brokerage_Plan &&  JSON.parse(brokerData?.Brokerage_Plan);
  const Brokerage_Plan_keys =Brokerage_Plan && Object.keys(Brokerage_Plan);
  const Brokerage_Plan_keys_first =Brokerage_Plan_keys && Brokerage_Plan_keys[0];
  console.log(Brokerage_Plan_keys_first);

  useEffect(() => {
    if (Brokerage_Plan_keys && Brokerage_Plan_keys.length > 0 && activePlan === "") {
      // Set the initial active plan to the first key in the Brokerage_Plan object
      setActivePlan(Brokerage_Plan_keys[0]);
    }
  }, [Brokerage_Plan_keys]);

  console.log(activePlan);

  const Platforms_Offered = brokerData?.Platforms_Offered && JSON.parse(brokerData?.Platforms_Offered);
  console.log(Platforms_Offered);
  
  const Other_Investment_Options = brokerData?.Other_Investment_Options && JSON.parse(brokerData?.Other_Investment_Options);
  const Other_Investment_Options_keys = Other_Investment_Options && Object.keys(Other_Investment_Options);
  console.log(Other_Investment_Options_keys);

  const Additional_Features = brokerData?.Additional_Features && JSON.parse(brokerData?.Additional_Features);
  const Additional_Features_keys = Additional_Features && Object.keys(Additional_Features);
  console.log(Additional_Features_keys);

  const Cons = brokerData?.Cons && JSON.parse(brokerData?.Cons);
  console.log(Cons);
  const Pros = brokerData?.Pros && JSON.parse(brokerData?.Pros);
  console.log(Pros);
  const Product_Basket = brokerData?.Product_Basket && JSON.parse(brokerData?.Product_Basket);
  console.log(Product_Basket);


  







const handleButtonClick = (planName) => {
  setActivePlan(activePlan === planName ?  planName : planName);
};

// const renderPlanDetails = (plan) => (
//   <div className="plan-details">
//     {Object.entries(plan).map(([key, value]) => (
//       <div key={key} className="plan-section">
//         <h3 className="plan-section-title">{key}</h3>
//         <div className="plan-section-content">
//           {Object.entries(value).map(([subKey, subValue]) => (
//             <div key={subKey} className="plan-item">
//               <span className="plan-item-key">{subKey}</span>
//               <span className="plan-item-value">{subValue}</span>
//             </div>
//           ))}
//         </div>
//       </div>
//     ))}
//   </div>
// );


// const renderPlanDetails = (plan) => {
//   console.log(plan); // Moved to a separate block

//   return (
//     <div className="plan-details">
//       {Object.entries(plan).map(([key, value]) => (
//         <div key={key} className="plan-section">
//           <h3 className="plan-section-title">{key}</h3>
//           <div className="plan-section-content">
//             {Object.entries(value).map(([subKey, subValue]) => {
//               // Split the `subValue` into words
//               const words = subValue.split(' ');
              
//               // Extract the first word and the rest of the words
//               const firstWord = words.shift(); // Removes and returns the first word
//               const restOfWords = words.join(' '); // Joins the remaining words back into a string

//               return (
//                 <div key={subKey} className="plan-item">
//                   <span className="plan-item-key">{subKey}</span>
//                   <span className="plan-item-value">
//                     <span style={{ color: '#00796b'}}>{firstWord}</span> <span style={{ color: 'black' }}>{restOfWords}</span>
//                   </span>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };


const renderPlanDetails = (plan) => (
  // console.log(plan);
  <div className="plan-details">
    {plan && Object.entries(plan).map(([key, value]) => (
      <div key={key} className="plan-section">
        <h3 className="plan-section-title">{key}</h3>
        <div className="plan-section-content">
          {Object.entries(value).map(([subKey, subValue]) => {
            // Split the `subValue` into words
            const words = subValue.split(' ');
            
            // Extract the first word and the rest of the words
            const firstWord = words.shift(); // Removes and returns the first word
            const restOfWords = words.join(' '); // Joins the remaining words back into a string

            return (
              <div key={subKey} className="plan-item">
                <span className="plan-item-key">{subKey}</span>
                <span className="plan-item-value">
                  <span style={{ color: '#00796b'}}>{firstWord}</span> <span style={{ color: 'black' }}>{restOfWords}</span>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    ))}
  </div>
);

  
  return (
    <>
    <Helmet>
        <title>{`${brokerData?.Broker_Name} - Comprehensive Trading and Investment Services`}</title>
        <meta name="description" content={`${brokerData?.Broker_Name}, a leading ${brokerData?.Broker_Type} in India, offers a wide range of trading and investment services including equity, commodities, futures, and options. With a robust platform and competitive brokerage plans, ${brokerData?.Broker_Name} is a trusted choice for investors.`} />
        <meta name="keywords" content={`${brokerData?.Broker_Name}, ${brokerData?.Broker_Type}, equity trading, commodity trading, futures trading, options trading, stock market, investment services, IPOtank, IPO tank`} />
     
    <meta property="og:url" content={`https://www.ipotank.com/broker/${Params["broker-details"]}`} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={`${brokerData?.Broker_Name} - Comprehensive Trading and Investment Services`} />
    <meta property="og:description" content={`${brokerData?.Broker_Name}, a leading ${brokerData?.Broker_Type} in India, offers a wide range of trading and investment services including equity, commodities, futures, and options. With a robust platform and competitive brokerage plans, ${brokerData?.Broker_Name} is a trusted choice for investors.`} />
      </Helmet>

      <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    > 
    <div style={{ width: '90%', maxWidth: '1200px' }}>
    {(deviceInfo === "app" && navigation) ? (<br/>) : (<div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"10px"}} >
      <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
        <p>
          Home
        </p>
      </Link>
      <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
    
      <Link to={`/brokers`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
        <p>Brokers</p>
      </Link>
      <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      <Link to={`/broker/${Params["broker-details"]}`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
      onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
        <p>{Params["broker-details"].replace(/-/g, " ") // Replace hyphens with spaces
              .toLowerCase() // Convert the entire string to lowercase
              .split(" ") // Split the string into an array of words
              .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
              .join(" ")}</p>
      </Link>
    </div>)}
 
    </div>
    </div>


  
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize:"14px",
          marginTop:"5px",
          marginBottom:"30px",
          textAlign:"justify",
        }}
      >
           
      <div  style={{width: "90%",maxWidth:"1200px",minWidth:"100px",height:"auto" }}>


      {/* <div style={{ margin:"0px 0px 0px 0px",borderRadius: "10px",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#f9f9f9",padding:"10px"}}> */}
 
     {/* <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",gap:"20px",alignItems:"center"}}><img src={brokerData?.Broker_Logo} alt="" style={{width:"100px",maxHeight:"100px"}}/><div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}><div><h2 style={{margin:"0px"}}>{brokerData?.Broker_Name}</h2><div>{brokerData?.Broker_Type}</div></div><a href={brokerData?.Open_Demat_Account_Url} target='blank' style={{textDecoration:"none"}}>Open Demat Account</a></div></div> */}

     <div className="broker-card">
    <img 
      src={brokerData?.Broker_logo} 
      alt={`${brokerData?.Broker_Name} Logo`} 
      loading="lazy"
    />
    <div 
      className={`broker-card-content ${window.innerWidth <= 768 ? 'broker-card-content-column' : ''}`}
    >
      <div style={{ flex: "1" }}> 
        <h2 className="broker-name">
          {brokerData?.Broker_Name}
        </h2>
        <div className="broker-type">
          {brokerData?.Broker_Type}
        </div>
      </div>
      <a 
        href={brokerData?.Open_Demat_Account_Url} 
        target="blank" 
        className="open-demat-btn"
      >
        Open Demat Account
      </a>
    </div>
  </div>

    {/* </div>  */}


       
     <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 0px 0px",}}>About Broker</h3>
     {/* <div style={{marginTop:"5px",color:"#666",textAlign:"left"}}>{brokerData?.About_Broker}</div> */}
     <div style={{ marginTop: "5px", color: "#666", textAlign: "left" }}>
  {brokerData?.About_Broker ? (
    brokerData.About_Broker
  ) : (
    <div style={{ color: "#ccc", height: "20px" }}>Loading...</div>
  )}
</div>



     <div style={{ margin:"20px 0px 0px 0px",borderRadius: "10px",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#f9f9f9",padding:"20px"}}>
     <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0px 0px 10px 0px"}}>Product Basket</h3>
     <div style={{margin:"0px 0px 10px 0px",color:"#666",fontSize:"13px"}}>The segments in which you can invest (includes Equity, Commodity, Currency, Futures and Options) through this broker</div>
     <div className="d-flex flex-wrap gap-2 flex-grow-1">
     {Product_Basket?.map((i)=>
     <span className="badge_span" data-role="Equity" style={{fontWeight:"bold",backgroundColor:"white",boxShadow: "inset 0px 0px 50px -28px #00796b"}}>
            <i className="fa fa-check-circle" aria-hidden="true"></i> {i}
    </span>)} </div> </div> 

    <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 5px 0px"}}>Brokerage Plan</h3>
     <div style={{color:"#666",fontSize:"13px"}}>1 or more plans offered by this broker, which outlines, Account opening and maintenance fees charged by this broker, Brokerage charges and Margins provided. Use plan switcher to see the difference in offerings, if multiple plans are available.</div>
     <div className="brokerage-plans">
      <div className="plan-buttons">
        {Brokerage_Plan_keys?.map((planName) => (
          <button
            key={planName}
            className={`plan-button ${activePlan === planName ? 'active' : ''}`}
            onClick={() => handleButtonClick(planName)}
          >
            {planName}
          </button>
        ))}
      </div>
      <div className="plan-content">
        {activePlan && renderPlanDetails(Brokerage_Plan[activePlan])}
      </div>
    </div>

    <div style={{ margin:"20px 0px 0px 0px",borderRadius: "10px",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#f9f9f9",padding:"20px"}}>
    <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0px 0px 10px 0px"}}>Platforms Offered</h3>
     <div style={{margin:"0px 0px 10px 0px",color:"#666",fontSize:"13px"}}>Set of software & applications offered by the broker for executing buy and sell orders online. These are names of their portals and mobile apps, for full desc- Full Platform Details.</div>
     <div className="d-flex flex-wrap gap-2 flex-grow-1">
     {Platforms_Offered?.map((i)=>
     <span className="badge_span" data-role="Equity" style={{fontWeight:"bold",backgroundColor:"white",boxShadow: "inset 0px 0px 50px -28px #00796b"}}
    //  style={{color:"#df7047",backgroundColor:"white",boxShadow: "inset 0px 0px 50px -28px rgb(255, 120, 71)"}}
     >{i}
    </span>)} </div> </div>


    <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 10px 0px"}}>Other Details</h3>
     {/* <div style={{margin:"0px 0px 8px 0px",color:"#666",fontSize:"13px"}}>Set of software & applications offered by the broker for executing buy and sell orders online. These are names of their portals and mobile apps, for full desc- Full Platform Details.</div> */}
     {/* <div style={{
  display: "flex",
  flexWrap: "wrap",
  gap: "10px", // Adjust the gap between columns if needed
  margin: "0 0 8px 0",
  fontSize:"13px"
}}>
  <div style={{
    flex: "1 1 calc(50% - 16px)", // Adjust the width of each column
    padding: "20px",
    backgroundColor: "#e0f7fa",
    borderRadius: "10px",
    boxSizing: "border-box", // Ensure padding does not affect the width
    margin: "0 0 8px 0"
  }}>
    <h4 style={{ fontSize: "15px", margin: "0 0 10px 0", color: "#00796b" }}>Pros</h4>
    <div style={{ color: "#666", fontSize: "13px", margin: "0 0 5px 0" }}>
      Advantages and Strengths of this broker.
    </div>
    {brokerData?.Pros?.map((i) => (
      <ul style={{
        listStyle: "outside",
        listStyleType: "'⮚  '",
        paddingLeft: "20px",
        marginLeft: 0
      }}>
        <li style={{ textAlign: "left", color: "#00796b" }}>
          <span style={{ color: "black" }}>{i}</span>
        </li>
      </ul>
    ))}
  </div>

  <div style={{
    flex: "1 1 calc(50% - 16px)", // Adjust the width of each column
    padding: "20px",
    backgroundColor: "#fdecec",
    borderRadius: "10px",
    boxSizing: "border-box", // Ensure padding does not affect the width
    margin: "0 0 8px 0"
  }}>
    <h4 style={{ fontSize: "15px", margin: "0 0 10px 0", color: "#f56e6e" }}>Cons</h4>
    <div style={{ color: "#666", fontSize: "13px", margin: "0 0 5px 0" }}>
      Disadvantages of this broker that it needs to improve upon.
    </div>
    {brokerData?.Cons?.map((i) => (
      <ul style={{
        listStyle: "outside",
        listStyleType: "'⮚  '",
        paddingLeft: "20px",
        marginLeft: 0
      }}>
        <li style={{ textAlign: "left", color: "#f56e6e" }}>
          <span style={{ color: "black" }}>{i}</span>
        </li>
      </ul>
    ))}
  </div>
</div> */}
<div className="pros-cons-container">
  <div className="pros">
    <h4 style={{ fontSize: "15px", margin: "0 0 10px 0", color: "#00796b" }}>Pros</h4>
    <div style={{ color: "#666", fontSize: "13px", margin: "0 0 5px 0" }}>
      Advantages and Strengths of this broker.
    </div>
    {Pros?.map((i, index) => (
      <ul key={index} style={{
        listStyle: "outside",
        listStyleType: "'⮚  '",
        paddingLeft: "20px",
        marginLeft: 0
      }}>
        <li style={{ textAlign: "left", color: "#00796b" }}>
          <span style={{ color: "black" }}>{i}</span>
        </li>
      </ul>
    ))}
  </div>

  <div className="cons">
    <h4 style={{ fontSize: "15px", margin: "0 0 10px 0", color: "#f56e6e" }}>Cons</h4>
    <div style={{ color: "#666", fontSize: "13px", margin: "0 0 5px 0" }}>
      Disadvantages of this broker that it needs to improve upon.
    </div>
    {Cons?.map((i, index) => (
      <ul key={index} style={{
        listStyle: "outside",
        listStyleType: "'⮚  '",
        paddingLeft: "20px",
        marginLeft: 0
      }}>
        <li style={{ textAlign: "left", color: "#f56e6e" }}>
          <span style={{ color: "black" }}>{i}</span>
        </li>
      </ul>
    ))}
  </div>
</div>

     {/* <div style={{margin:"0px 0px 8px 0px",fontSize:"13px"}}><div style={{padding:"20px",backgroundColor:"#e0f7fa",borderRadius:"10px"}}>
     <h4 style={{fontSize:"15px",margin:"0 0 5px 0",color:"#00796b"}}>Pros</h4>
     <div style={{color:"#666",fontSize:"13px",margin:"0 0 5px 0",}}>Advantages and Strengths of this broker.</div>
     {brokerData?.Pros?.map((i)=>
     <ul style={{listStyle: "outside", listStyleType:"'⮚  '",paddingLeft: "20px", marginLeft: 0 }}> <li style={{ textAlign: "left", color: "#00796b" }}>
     <span style={{ color: "black" }}>{i}</span>
   </li>
    </ul>)} </div>
     <div style={{padding:"20px",backgroundColor:"#fdecec",borderRadius:"10px"}}>
     <h4 style={{fontSize:"15px",margin:"0 0 5px 0",color:"#f56e6e"}}>Cons</h4>
     <div style={{color:"#666",fontSize:"13px",margin:"0 0 5px 0",}}>Disadvantages of this broker that it needs to improve upon.</div>
     {brokerData?.Cons?.map((i)=>
     <ul style={{listStyle: "outside", listStyleType:"'⮚  '",paddingLeft: "20px", marginLeft: 0}}><li style={{ textAlign: "left", color: "#f56e6e" }}>
     <span style={{ color: "black" }}>{i}</span>
   </li>
    </ul>)} </div> </div> */}



{/* <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"20px 0px 5px 0px"}}>Additional Features</h3>
     <div style={{margin:"0px 0px 8px 0px",color:"#666",fontSize:"13px"}}>Extra features provided by the broker at little or no extra cost. Like research reports, SMS alerts etc.</div>
     <div className="d-flex flex-wrap gap-2 flex-grow-1" style={{ borderRadius: "10px",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#f9f9f9",padding:"20px"}}>
     {Object.keys(brokerData?.Additional_Features).map((i)=>
     <span data-role="Equity" style={{marginRight:"20px"}}>
          {brokerData?.Additional_Features[i] === "Yes" ? <span className="badge_span" data-role="Equity" style={{padding:"0px 5px",border:"1px solid #00796b",marginRight:"5px",backgroundColor:"#00796b",color:"#e0f7fa"}}>✔</span> : <span className="badge_span" data-role="Equity" style={{padding:"0px 5px",border:"1px solid #f56e6e",marginRight:"5px",backgroundColor:"#f56e6e",color:"#fdecec"}}>✘</span>}{i}
            
    </span>)} </div>  */}

<div style={{ margin:"20px 0px 0px 0px",borderRadius: "10px",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#f9f9f9",padding:"20px"}}>
    <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0px 0px 10px 0px",}}>Additional Features</h3>
     <div style={{margin:"0px 0px 10px 0px",color:"#666",fontSize:"13px"}}>Extra features provided by the broker at little or no extra cost. Like research reports, SMS alerts etc.</div>
     <div className="d-flex flex-wrap gap-2 flex-grow-1" >
     {Additional_Features_keys?.map((i)=>
     <span data-role="Equity" style={{marginRight:"20px"}}>
          {Additional_Features[i] === "Yes" ? <span className="badge_span" data-role="Equity" style={{padding:"0px 4px",border:"1px solid #00796b",marginRight:"5px",backgroundColor:"#00796b",color:"#e0f7fa",fontSize:"11px"}}>✔</span> : <span className="badge_span" data-role="Equity" style={{padding:"0px 4px",border:"1px solid #f56e6e",marginRight:"5px",backgroundColor:"#f56e6e",color:"#fdecec",fontSize:"11px"}}>✘</span>}{i}
           
    </span>)} </div> </div>


<div style={{ margin:"20px 0px 0px 0px",borderRadius: "10px",
  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#f9f9f9",padding:"20px"}}>
    <h3 style={{borderBottom: "1px solid #dddcdd",paddingBottom:"10px",margin:"0px 0px 10px 0px",}}>Other Investment Options</h3>
     <div style={{margin:"0px 0px 10px 0px",color:"#666",fontSize:"13px"}}>Services provided by the broker for investing in other financial assets such as Mutual funds, Insurance, Bonds etc.</div>
     <div className="d-flex flex-wrap gap-2 flex-grow-1" >
     {Other_Investment_Options_keys?.map((i)=>
     <span data-role="Equity" style={{marginRight:"20px"}}>
          {Other_Investment_Options[i] === "Yes" ? <span className="badge_span" data-role="Equity" style={{padding:"0px 4px",border:"1px solid #00796b",marginRight:"5px",backgroundColor:"#00796b",color:"#e0f7fa",fontSize:"11px"}}>✔</span> : <span className="badge_span" data-role="Equity" style={{padding:"0px 4px",border:"1px solid #f56e6e",marginRight:"5px",backgroundColor:"#f56e6e",color:"#fdecec",fontSize:"11px"}}>✘</span>}{i}
          
    </span>)} </div> </div>
    
     </div> </div>
    </>
  );
};

export default BrokerDetails;
