import React, { useState, useEffect } from "react";

import "../personalfinance.css";
import { Helmet } from "react-helmet";


// import React, { useState, useEffect } from 'react';
import LumpsumCalculator from './Calculators/LumpsumCalculator';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { TextField, InputAdornment } from '@mui/material';
import '../SipCalculator.css';
import { Link } from "react-router-dom";



const sections1 = [
    {
        title: "APY",
    url:"https://ipotank.com/p/calculators/apy-calculator", 
    description:
    "Calculate your monthly investments under Atal Pension Yojana",
    },
    {
        title: "CAGR",
    url:"https://ipotank.com/p/calculators/cagr-calculator", 
    description:
    "The simplest compound annual growth rate calculator",
    },
    {
        title: "Car Loan EMI",
    url:"https://ipotank.com/p/calculators/carloanemi-calculator", 
    description:
    "Calculate your car loan EMI",
    },
    {
        title: "Compound Interest",
    url:"https://ipotank.com/p/calculators/compoundinterest-calculator", 
    description:
    "Calculate compound interest with ease",
    },
    {
        title: "EMI",
    url:"https://ipotank.com/p/calculators/emi-calculator", 
    description:
    "Calculate EMI on your loans – home loan, car loan or personal loan",
    },
    {
        title: "FD",
    url:"https://ipotank.com/p/calculators/fd-calculator", 
    description:
    "Check returns on your fixed deposits (FDs) without any hassle",
    },
    {
        title: "Gratuity",
    url:"https://ipotank.com/p/calculators/gratuity-calculator", 
    description:
    "Calculate how much gratuity you will get when you retire",
    },
    {
        title: "GST",
    url:"https://ipotank.com/p/calculators/gst-calculator", 
    description:
    "Calculate your payable GST amount with a few clicks",
    },
    {
        title: "Home Loan EMI",
    url:"https://ipotank.com/p/calculators/homeloanemi-calculator", 
    description:
    "Calculate your home loan EMI",
    },
    {
        title: "HRA",
    url:"https://ipotank.com/p/calculators/hra-calculator", 
    description:
    "Calculate your House Rent Allowance (HRA)",
    },
    {
        title: "Inflation",
    url:"https://ipotank.com/p/calculators/inflation-calculator", 
    description:
    "Calculate inflation adjusted prices",
    },
    {
        title: "Simple Interest",
    url:"https://ipotank.com/p/calculators/simpleinterest-calculator", 
    description:
    "Calculate simple interest on your loans and saving schemes investments",
    },
    {
        title: "Lumpsum",
    url:"https://ipotank.com/p/calculators/lumpsum-calculator", 
    description:
    "Calculate returns for lumpsum investments to achieve your financial goals",
    },
    {
        title: "Mutual Fund",
    url:"https://ipotank.com/p/calculators/mutualfund-calculator", 
    description:
    "Calculate the returns on your mutual fund investments",
    },
    {
        title: "NPS",
    url:"https://ipotank.com/p/calculators/nps-calculator", 
    description:
    "Calculate returns for your National Pension Scheme (NPS)",
    },
    {
        title: "NSC",
    url:"https://ipotank.com/p/calculators/nsc-calculator", 
    description:
    "Calculate your returns under National Savings Certificate scheme",
    },
    {
        title: "Post Office MIS",
    url:"https://ipotank.com/p/calculators/postofficemis-calculator", 
    description:
    "Calculate post office monthly income scheme returns",
    },
    {
        title: "PPF",
    url:"https://ipotank.com/p/calculators/ppf-calculator", 
    description:
    "Calculate your returns on Public Provident Fund (PPF)",
    },
    {
        title: "RD",
    url:"https://ipotank.com/p/calculators/rd-calculator", 
    description:
    "Check returns on your Recurring Deposit (RD) in just a few clicks",
    },
    {
        title: "Salary",
    url:"https://ipotank.com/p/calculators/salary-calculator", 
    description:
    "Calculate your net take home salary",
    },
    {
        title: "SIP",
    url:"https://ipotank.com/p/calculators/sip-calculator", 
    description:
    "Calculate how much you need to save or how much you will accumulate with your SIP",
    },
    {
        title: "SCSS",
    url:"https://ipotank.com/p/calculators/scss-calculator", 
    description:
    "Calculate senior citizens savings scheme returns",
    },
    {
        title: "Step Up SIP",
    url:"https://ipotank.com/p/calculators/stepupsip-calculator", 
    description:
    "Calculate SIP Returns with an Yearly Raise",
    },
    {
        title: "Flat vs Reducing rate",
    url:"https://ipotank.com/p/calculators/flat-vs-reducing-rate-calculator", 
    description:
    "Compare monthly EMI in Flat and Reducing balance interest rate schemes",
    },
    {
        title: "Sukanya Smariddhi Yojana",
    url:"https://ipotank.com/p/calculators/sukanyasamriddhi-calculator", 
    description:
    "Calculate returns for Sukanya Smariddhi Yojana (SSY) as per your investment",
    },
    {
        title: "SWP",
    url:"https://ipotank.com/p/calculators/swp-calculator", 
    description:
    "Calculate your final amount with Systematic Withdrawal Plans (SWP)",
    },
    {
        title: "TDS",
    url:"https://ipotank.com/p/calculators/tds-calculator", 
    description:
    "Calculate your TDS deductions",
    },
];





function FinancialCalculators() {

  


const [monthlyInvestment, setMonthlyInvestment] = useState(25000);
const [returnRate, setReturnRate] = useState(12);
const [timePeriod, setTimePeriod] = useState(10);
const [isLumpsum, setIsLumpsum] = useState(false);
const [totalInvestment, settotalInvestment] = useState(25000);

const [principal, setPrincipal] = useState(100000);
const [estReturns, setEstReturns] = useState(0);

const [investment, setInvestment] = useState(100000);
const [lumpsumReturnRate, setLumpsumReturnRate] = useState(8.2);
const [lumpsumTimePeriod, setLumpsumTimePeriod] = useState(4);

const investedAmountSIP = monthlyInvestment * 12 * timePeriod;
    const totalValueSIP = Math.round(
      (monthlyInvestment * ((Math.pow(1 + returnRate / 100 / 12, 12 * timePeriod) - 1) / (returnRate / 100 / 12))) * (1 + returnRate / 100 / 12));
      const estReturnsSIP = totalValueSIP - investedAmountSIP ; 

const data = isLumpsum
  ? [
    { name: 'Invested amount', value: totalInvestment },
    { name: 'Est. returns', value: estReturns }
    ]
   : [
    { name: 'Invested amount', value: investedAmountSIP },
    { name: 'Est. returns', value: estReturnsSIP }
    ];
   
const COLORS = ['#00A86B', '#007bb5'];

const handleLumpsumClick = () => {
  setIsLumpsum(true);
};

const handleSIPClick = () => {
  setIsLumpsum(false);
};

  const handleChange = (e) => {
    const value = Number(e.target.value);
    setMonthlyInvestment(value > 0 ? value : '');
  };

useEffect(() => {
  setMonthlyInvestment(25000);
  setReturnRate(12);
  setTimePeriod(10);
}, []);


  return (
    <>
        <Helmet>
        <title>Financial Planning Calculators - IPOtank | Calculate Your Investment Online</title>
      <meta
        name="description"
        content="Take your Investment strategy to the next level using IPOtank&#039;s online Financial planning Calculators for different investment methods. Choose the best investment option &amp; achieve your financial goals."
      />
      <meta
        name="keywords"
        content="APY Calculator,
CAGR Calculator,
Car Loan EMI,
Compound Interest,
EMI,
FD,
Gratuity Calculator,
GST Calculator,
Home Loan EMI,
HRA Calculator,
Inflation Calculator,
Flat vs Reducing rate Calculator,
Lumpsum Calculator,
Mutual Fund Calculator,
NPS Calculator,
NSC Calculator,
Post Office MIS Calculator,
PPF,
RD,
Salary,
SIP,
SCSS,
Step Up SIP Calculator,
Simple Interest Calculator,
Sukanya Smariddhi Yojana Calculator,
SWP Calculator,
TDS Calculator"
      />


    {/* <title>Learn Personal Finance - Mutual Funds, Stocks and Saving Schemes</title> */}
    {/* <meta name="description" content="Manage your personal finance with a vast choice of online investment avenues at IPOtank. Learn how to save and grow your money by investing in stocks, mutual funds, savings schemes, and so on. Also, get a grasp on taxability as well." />
    <meta name="keywords" content={`Share Buybacks, Top Buybacks ${new Date().getFullYear()}, Buyback Opportunities, Investment Strategies, Stock Repurchase, Buyback Experts, IPOtank, IPO tank`} /> */}

    <meta property="og:url" content="https://www.ipotank.com/p/calculators" /> 
    <meta property="og:type" content="website" /> 
    <meta property="og:title" content="Financial Planning Calculators - IPOtank | Calculate Your Investment Online" /> 
    <meta property="og:description" content="Take your Investment strategy to the next level using IPOtank&#039;s online Financial planning Calculators for different investment methods. Choose the best investment option &amp; achieve your financial goals." />
</Helmet>


    <div style={{ display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',



    fontFamily: "GrowwSans, NotoSans, system-ui", // Set your desired font
   
    fontWeight: "535",
    color:"#44475b"
  }}>
    
   
     <div style={{ display: "flex",
    flexDirection: "column",
    justifyContent:"space-between",
    width: "100%",
    maxWidth: "1250px"
}}>
    <div style={{fontFamily: "GrowwSans, NotoSans, system-ui", display:"flex",flexDirection:"column",flexWrap:"wrap",fontSize:"12px",color:"#7c7e8c",justifyContent:"space-between",gap:"0rem",padding:"2rem 1.5rem 0.6rem 1.5rem",lineHeight:"30px"}}>
      <div>
          <a href="/" className="nevigationlink">
            <span className="bc785BreadText bodySmall bc785Link" style={{ fontSize: '12px' }}>Home</span>
          </a>
          <span className="bc785BreadSpace">&gt;</span>
          {/* <a href="/p" className="nevigationlink"> */}
          {/* <span className="bc785BreadText bodySmall bc785Last truncate" style={{ fontSize: '12px', lineHeight: '1.2' }}>Personal Finance</span> */}
          {/* </a> */}


          <a href="/p" className="nevigationlink">
          <span className="bc785BreadText bodySmall bc785Link" style={{ fontSize: '12px' }}>Personal Finance</span>
        </a>
        <span className="bc785BreadSpace">&gt;</span>
        <span className="bc785BreadText bodySmall bc785Last truncate" style={{ fontSize: '12px', lineHeight: '1.2' }}>
        Financial Calculators
        </span>
        </div></div></div></div>
        <div style={{    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "50px",
    // borderBottom:"1px solid #ddd",
    // boxShadow:"#fcd5bcb8 550px 0px 400px -70px inset",
    fontWeight:"600",
    fontSize:"25px",
    fontFamily: "GrowwSans, NotoSans, system-ui", 
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "100%",
    // backgroundImage: "url(https://pic.616pic.com/bg_w1180/00/00/43/TKR6wZcepo.jpg)",
    flexDirection: "column",
  }}>Financial Calculators</div>
    <div className="container">
      {sections1.map((section, index) => (
        <Card key={index} section={section} />
      ))}
      {/* <div style={{display:"flex",gap: "20px",flexDirection:"column"}}>
      {sections1.map((section, index) => (
        <Card key={index} section={section} />
      ))}
      </div> */}
      {/* <div style={{display:"flex",gap: "20px",flexDirection:"column"}}>
      {sections2.map((section, index) => (
        <Card key={index} section={section} />
      ))}
      </div> */}
      {/* <div style={{display:"flex",gap: "20px",flexDirection:"column"}}>
      {sections3.map((section, index) => (
        <Card key={index} section={section} />
      ))}
      </div> */}
    </div></>
  );
}

function Card({ section }) {
  const [showMore, setShowMore] = useState(false);
  const maxLinksToShow = 5;
//   const linksArray = Object.entries(section.links); // Convert object to array of [key, value] pairs
const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
    to={section.url}
    // target="_blank"
    rel="noopener noreferrer"
    style={{ color: "#333", textDecoration: "none" }}
  >
    <div
      className="card"
      style={{
        border: "1px solid #e0e0e0",
        padding: "16px",
        backgroundColor: "#fff",
        // transform: isHovered ? "scale(1.05)" : "scale(1)",
        boxShadow: isHovered
          ? "0px 4px 8px rgba(0, 0, 0, 0.2)"
          : "none",
        transition: "all 0.3s ease",
        minHeight:"120px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h3>
        <a
          href={section.url}
          // target="_blank"
          rel="noopener noreferrer"
          style={{ color: isHovered
          ? "#ff7847":"#333", textDecoration: "none" }}

        >
          {section.title}
        </a>
      </h3>
      <p style={{ fontSize: "14px", fontWeight: "500" }}>
        {section.description}
      </p>
    </div>
  </Link>
    // <Link to={section.url} target="_blank" rel="noopener noreferrer" style={{color:"#333",textDecoration:"none"}}>
    // <div className="card">
    //   <h3><a href={section.url} target="_blank" rel="noopener noreferrer" style={{color:"#333",textDecoration:"none"}}>{section.title}</a></h3>
    //   <p style={{fontSize:"14px",fontWeight:"500"}}>{section.description}</p>
    // </div>
    // </Link>
  );
}

export default FinancialCalculators;
