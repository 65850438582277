import React, { useState, useEffect } from "react";

import "../personalfinance.css";
import { Helmet } from "react-helmet";


// import React, { useState, useEffect } from 'react';
import LumpsumCalculator from './Calculators/LumpsumCalculator';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { TextField, InputAdornment } from '@mui/material';
import '../SipCalculator.css';

const sections1 = [
  {
    title: "Aadhaar Card",
    url:"https://ipotank.com/p/aadhaar-card",
    description:
      "The UIDAI has been tasked with providing all people with a 12-digit unique identity number called as Aadhaar.",
    links: {
      "Baal Aadhaar for Children":"https://ipotank.com/p/aadhaar-card/baal-aadhaar-for-children",
      "Aadhaar PVC Card":"https://ipotank.com/p/aadhaar-card/aadhaar-pvc-card",
      "Aadhaar Biometric":"https://ipotank.com/p/aadhaar-card/aadhaar-biometric",
      "Check Aadhaar Card Status":"https://ipotank.com/p/aadhaar-card/check-aadhaar-card-status",
      "mAadhaar App":"https://ipotank.com/p/aadhaar-card/maadhaar-app",
      "Aadhaar eKYC":"https://ipotank.com/p/aadhaar-card/aadhaar-ekyc",
      "Change Name in Aadhar Card after Marriage":"https://ipotank.com/p/aadhaar-card/change-name-in-aadhar-card-after-marriage",
      "Aadhaar Card Correction":"https://ipotank.com/p/aadhaar-card/aadhaar-card-correction",
      "Instant PAN through Aadhaar":"https://ipotank.com/p/aadhaar-card/instant-pan-through-aadhaar",
      "Link Aadhaar Card to Bank Account":"https://ipotank.com/p/aadhaar-card/link-aadhaar-card-to-bank-account",
      "Link Aadhar with Ration Card":"https://ipotank.com/p/aadhaar-card/link-aadhar-with-ration-card",
      "Link Aadhaar with EPF Account":"https://ipotank.com/p/aadhaar-card/link-aadhaar-with-epf-account",
      "IRCTC Aadhaar Linking":"https://ipotank.com/p/aadhaar-card/irctc-aadhaar-linking",
      "Aadhaar Card Customer Care Number":"https://ipotank.com/p/aadhaar-card/aadhaar-card-customer-care-number",
      "Aadhaar Card Enrolment Centres":"https://ipotank.com/p/aadhaar-card/aadhaar-card-enrolment-centres",
      "Aadhar Card Center in Delhi":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-delhi",
      "Aadhar Card Center in Noida":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-noida",
      "Aadhar Card Center in Gurgaon":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-gurgaon",
      "Aadhar Card Center in Hyderabad":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-hyderabad",
      "Aadhar Card Center in Kolkata":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-kolkata",
      "Aadhar Card Center in Bangalore":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-bangalore",
      "Aadhar Card Center in Chennai":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-chennai",
      "Aadhar Card Center in Mumbai":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-mumbai",
      "Aadhar Card Center in Pune":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-pune",
      "Aadhar Card Center in Patna":"https://ipotank.com/p/aadhaar-card/aadhar-card-center-in-patna",
      "Aadhaar Card Center in Vadodara":"https://ipotank.com/p/aadhaar-card/aadhaar-card-center-in-vadodara",
      "Aadhaar Card Center in Ahmedabad":"https://ipotank.com/p/aadhaar-card/aadhaar-card-center-in-ahmedabad",
      "Aadhaar Card Center in Surat":"https://ipotank.com/p/aadhaar-card/aadhaar-card-center-in-surat",
      "Aadhaar Card Center in Ghaziabad":"https://ipotank.com/p/aadhaar-card/aadhaar-card-center-in-ghaziabad",

    },
  },
  {
    title: "PAN Card",
    url:"https://ipotank.com/p/pan-card",
    description:
      "Permanent Account Number (PAN) is a method of identifying different taxpayers in the country. It is a 10-digit alphanumeric identification number.",
    links: {
      "PAN Card Eligibility":"https://ipotank.com/p/pan-card/pan-card-eligibility",
      "Track PAN Card Status":"https://ipotank.com/p/pan-card/track-pan-card-status",
      "Download e-PAN Card online":"https://ipotank.com/p/pan-card/download-e-pan-card-online",
      "PAN Verification":"https://ipotank.com/p/pan-card/pan-verification",
      "Duplicate PAN Card":"https://ipotank.com/p/pan-card/duplicate-pan-card",
      "Minor PAN Card":"https://ipotank.com/p/pan-card/minor-pan-card",
      "PAN Card Acknowledgement Number":"https://ipotank.com/p/pan-card/pan-card-acknowledgement-number",
      "AO Codes for PAN":"https://ipotank.com/p/pan-card/ao-codes-for-pan",
      "Change Photo and Signature in PAN Card":"https://ipotank.com/p/pan-card/change-photo-and-signature-in-pan-card",
      "Know Your PAN Card":"https://ipotank.com/p/pan-card/know-your-pan-card",
      "PAN card Customer Care Number":"https://ipotank.com/p/pan-card/pan-card-customer-care-number",
      "NSDL":"https://ipotank.com/p/pan-card/nsdl",
      "PAN Form 49A":"https://ipotank.com/p/pan-card/pan-form-49a",
      "PAN Card Form 49AA":"https://ipotank.com/p/pan-card/pan-card-form-49aa",
    },
  }
];
const sections2 = [
  {
    title: "Tax",
    url:"https://ipotank.com/p/tax",
    description:
      "Tax is a fee or charge levied by any government on an individual or an organization to collect revenue for public works to provide best facilities.",
    links: {
      "Income Tax":"https://ipotank.com/p/tax/income-tax",
      "Section 80C":"https://ipotank.com/p/tax/section-80c",
      "TDS":"https://ipotank.com/p/tax/tds",
      "GST":"https://ipotank.com/p/tax/gst",
      "Income Tax Act":"https://ipotank.com/p/tax/income-tax-act",
      "Income Tax Login":"https://ipotank.com/p/tax/income-tax-login",
      "income Tax Return":"https://ipotank.com/p/tax/income-tax-return",
      "Income Tax e-Filing":"https://ipotank.com/p/tax/income-tax-e-filing",
      "Income Tax Refund":"https://ipotank.com/p/tax/income-tax-refund",
      "Income Tax Slabs":"https://ipotank.com/p/tax/income-tax-slabs",
      "Types of ITR Forms":"https://ipotank.com/p/tax/types-of-itr-forms",
      "Income Tax Online Payment":"https://ipotank.com/p/tax/income-tax-online-payment",
      "Income Tax Return Due Dates":"https://ipotank.com/p/tax/income-tax-return-due-dates",
      "GST Registration":"https://ipotank.com/p/tax/gst-registration",
      "Types of GST":"https://ipotank.com/p/tax/types-of-gst",
      "CGST":"https://ipotank.com/p/tax/cgst",
      "SGST":"https://ipotank.com/p/tax/sgst",
      "IGST":"https://ipotank.com/p/tax/igst",
      "GSTR - 1":"https://ipotank.com/p/tax/gstr---1",
      "GSTR 3B":"https://ipotank.com/p/tax/gstr-3b",
      "GST Filing":"https://ipotank.com/p/tax/gst-filing",
      "GST Rates":"https://ipotank.com/p/tax/gst-rates",
      "TDS Rates":"https://ipotank.com/p/tax/tds-rates",
      "TDS Return":"https://ipotank.com/p/tax/tds-return",
      "TDS Traces":"https://ipotank.com/p/tax/tds-traces",
      "TDS on FD":"https://ipotank.com/p/tax/tds-on-fd",
      "TDS on Salary":"https://ipotank.com/p/tax/tds-on-salary",
      "Section 194I - TDS on Rent":"https://ipotank.com/p/tax/section-194i---tds-on-rent",
      "Section 194J":"https://ipotank.com/p/tax/section-194j",
      "TDS Refund Status":"https://ipotank.com/p/tax/tds-refund-status",
      "TDS Payment Due Date":"https://ipotank.com/p/tax/tds-payment-due-date",
    },
  },
  {
    title: "Financial Calculators",
    url:"https://ipotank.com/p/calculators",
    description:
      "Empower your financial decisions with our advanced suite of calculators, meticulously designed to deliver precise insights for a wide range of needs.",
    links: {
      "APY":"https://ipotank.com/p/calculators/apy-calculator",
      "CAGR":"https://ipotank.com/p/calculators/cagr-calculator",
      "Car Loan EMI":"https://ipotank.com/p/calculators/carloanemi-calculator",
      "Compound Interest":"https://ipotank.com/p/calculators/compoundinterest-calculator",
      "EMI":"https://ipotank.com/p/calculators/emi-calculator",
      "FD":"https://ipotank.com/p/calculators/fd-calculator",
      "Gratuity":"https://ipotank.com/p/calculators/gratuity-calculator",
      "GST":"https://ipotank.com/p/calculators/gst-calculator",
      "Home Loan EMI":"https://ipotank.com/p/calculators/homeloanemi-calculator",
      "HRA":"https://ipotank.com/p/calculators/hra-calculator",
      "Inflation":"https://ipotank.com/p/calculators/inflation-calculator",
      "Flat vs Reducing rate":"https://ipotank.com/p/calculators/flat-vs-reducing-rate-calculator",
      "Lumpsum":"https://ipotank.com/p/calculators/lumpsum-calculator",
      "Mutual Fund":"https://ipotank.com/p/calculators/mutualfund-calculator",
      "NPS":"https://ipotank.com/p/calculators/nps-calculator",
      "NSC":"https://ipotank.com/p/calculators/nsc-calculator",
      "Post Office MIS":"https://ipotank.com/p/calculators/postofficemis-calculator",
      "PPF":"https://ipotank.com/p/calculators/ppf-calculator",
      "RD":"https://ipotank.com/p/calculators/rd-calculator",
      "Salary":"https://ipotank.com/p/calculators/salary-calculator",
      "SIP":"https://ipotank.com/p/calculators/sip-calculator",
      "SCSS":"https://ipotank.com/p/calculators/scss-calculator",
      "Step Up SIP":"https://ipotank.com/p/calculators/stepupsip-calculator",
      "Simple Interest":"https://ipotank.com/p/calculators/simpleinterest-calculator",
      "Sukanya Smariddhi Yojana":"https://ipotank.com/p/calculators/sukanyasamriddhi-calculator",
      "SWP":"https://ipotank.com/p/calculators/swp-calculator",
      "TDS":"https://ipotank.com/p/calculators/tds-calculator",

    },
  }
];
const sections3 = [
  {
    title: "Equity Funds",
    description:
      "Equity Funds are those where fund managers try to offer great returns by spreading the investment across equity from different sectors.",
    links: {
      "Large Cap Funds": "https://example.com/large-cap-funds",
      "Mid Cap Funds": "https://example.com/mid-cap-funds",
      "Small Cap Funds": "https://example.com/small-cap-funds",
      "Multi Cap Funds": "https://example.com/multi-cap-funds",
      "ELSS Funds": "https://example.com/elss-funds",
      "Dividend Yield Funds": "https://example.com/dividend-yield-funds",
      "Growth Funds": "https://example.com/growth-funds",
    },
  },
  // {
  //   title: "Hybrid Funds",
  //   description:
  //     "Hybrid funds are a combination of equity and debt investments to help investors create a balanced portfolio.",
  //   links: {
  //     "Aggressive Funds": "https://example.com/aggressive-funds",
  //     "Balanced Funds": "https://example.com/balanced-funds",
  //     "Dynamic Asset Allocation": "https://example.com/dynamic-asset-allocation",
  //     "Conservative Funds": "https://example.com/conservative-funds",
  //     "Arbitrage Funds": "https://example.com/arbitrage-funds",
  //     "Multi Asset Allocation": "https://example.com/multi-asset-allocation",
  //   },
  // }
];




function PersonalFinance() {

  


const [monthlyInvestment, setMonthlyInvestment] = useState(25000);
const [returnRate, setReturnRate] = useState(12);
const [timePeriod, setTimePeriod] = useState(10);
const [isLumpsum, setIsLumpsum] = useState(false);
const [totalInvestment, settotalInvestment] = useState(25000);

const [principal, setPrincipal] = useState(100000);
const [estReturns, setEstReturns] = useState(0);

const [investment, setInvestment] = useState(100000);
const [lumpsumReturnRate, setLumpsumReturnRate] = useState(8.2);
const [lumpsumTimePeriod, setLumpsumTimePeriod] = useState(4);

const investedAmountSIP = monthlyInvestment * 12 * timePeriod;
    const totalValueSIP = Math.round(
      (monthlyInvestment * ((Math.pow(1 + returnRate / 100 / 12, 12 * timePeriod) - 1) / (returnRate / 100 / 12))) * (1 + returnRate / 100 / 12));
      const estReturnsSIP = totalValueSIP - investedAmountSIP ; 

const data = isLumpsum
  ? [
    { name: 'Invested amount', value: totalInvestment },
    { name: 'Est. returns', value: estReturns }
    ]
   : [
    { name: 'Invested amount', value: investedAmountSIP },
    { name: 'Est. returns', value: estReturnsSIP }
    ];
   
const COLORS = ['#00A86B', '#007bb5'];

const handleLumpsumClick = () => {
  setIsLumpsum(true);
};

const handleSIPClick = () => {
  setIsLumpsum(false);
};

  const handleChange = (e) => {
    const value = Number(e.target.value);
    setMonthlyInvestment(value > 0 ? value : '');
  };

useEffect(() => {
  setMonthlyInvestment(25000);
  setReturnRate(12);
  setTimePeriod(10);
}, []);


  return (
    <>
        <Helmet>
        <title>Personal Finance Guide: Aadhaar, PAN, Taxes & Financial Calculators</title>
      <meta
        name="description"
        content="Explore a comprehensive guide to personal finance. Learn about Aadhaar and PAN card processes, tax-saving tips, and use our financial calculators for better planning."
      />
      <meta
        name="keywords"
        content="Personal Finance, Aadhaar Card, PAN Card, Tax Planning, Income Tax, Financial Calculators, Tax Saving, Investment Planning, GST Calculator, EMI Calculator"
      />


    {/* <title>Learn Personal Finance - Mutual Funds, Stocks and Saving Schemes</title> */}
    {/* <meta name="description" content="Manage your personal finance with a vast choice of online investment avenues at IPOtank. Learn how to save and grow your money by investing in stocks, mutual funds, savings schemes, and so on. Also, get a grasp on taxability as well." />
    <meta name="keywords" content={`Share Buybacks, Top Buybacks ${new Date().getFullYear()}, Buyback Opportunities, Investment Strategies, Stock Repurchase, Buyback Experts, IPOtank, IPO tank`} /> */}

    <meta property="og:url" content="https://www.ipotank.com/p" /> 
    <meta property="og:type" content="website" /> 
    <meta property="og:title" content="Personal Finance Guide: Aadhaar, PAN, Taxes & Financial Calculators" /> 
    <meta property="og:description" content="Explore a comprehensive guide to personal finance. Learn about Aadhaar and PAN card processes, tax-saving tips, and use our financial calculators for better planning." />
</Helmet>


    <div style={{ display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',



    fontFamily: "GrowwSans, NotoSans, system-ui", // Set your desired font
   
    fontWeight: "535",
    color:"#44475b"
  }}>
    
   
     <div style={{ display: "flex",
    flexDirection: "column",
    justifyContent:"space-between",
    width: "100%",
    maxWidth: "1250px"
}}>
    <div style={{fontFamily: "GrowwSans, NotoSans, system-ui", display:"flex",flexDirection:"column",flexWrap:"wrap",fontSize:"12px",color:"#7c7e8c",justifyContent:"space-between",gap:"0rem",padding:"2rem 1.5rem 0.6rem 1.5rem",lineHeight:"30px"}}>
      <div>
          <a href="/" className="nevigationlink">
            <span className="bc785BreadText bodySmall bc785Link" style={{ fontSize: '12px' }}>Home</span>
          </a>
          <span className="bc785BreadSpace">&gt;</span>
          {/* <a href="/p" className="nevigationlink"> */}
          <span className="bc785BreadText bodySmall bc785Last truncate" style={{ fontSize: '12px', lineHeight: '1.2' }}>Personal Finance</span>
          {/* </a> */}
        </div></div></div></div>
        <div style={{    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "50px",
    // borderBottom:"1px solid #ddd",
    // boxShadow:"#fcd5bcb8 550px 0px 400px -70px inset",
    fontWeight:"600",
    fontSize:"25px",
    fontFamily: "GrowwSans, NotoSans, system-ui", 
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "100%",
    // backgroundImage: "url(https://pic.616pic.com/bg_w1180/00/00/43/TKR6wZcepo.jpg)",
    flexDirection: "column",
  }}>Personal Finance</div>
    <div className="container">
      {/* {sections1.map((section, index) => (
        <Card key={index} section={section} />
      ))} */}
      <div style={{display:"flex",gap: "20px",flexDirection:"column"}}>
      {sections1.map((section, index) => (
        <Card key={index} section={section} />
      ))}
      </div>
      <div style={{display:"flex",gap: "20px",flexDirection:"column"}}>
      {sections2.map((section, index) => (
        <Card key={index} section={section} />
      ))}
      </div>
      {/* <div style={{display:"flex",gap: "20px",flexDirection:"column"}}>
      {sections3.map((section, index) => (
        <Card key={index} section={section} />
      ))}
      </div> */}
    </div></>
  );
}

function Card({ section }) {
  const [showMore, setShowMore] = useState(false);
  const maxLinksToShow = 5;
  const linksArray = Object.entries(section.links); // Convert object to array of [key, value] pairs

  return (
    <div className="card">
      <h3><a href={section.url}  rel="noopener noreferrer" style={{color:"#333",textDecoration:"none"}}>{section.title}</a></h3>
      <p style={{fontSize:"14px",fontWeight:"500"}}>{section.description}</p>
      <ul style={{fontSize:"14px",fontWeight:"400"}}>
        {linksArray
          .slice(0, showMore ? linksArray.length : maxLinksToShow)
          .map(([label, href], i) => (
            <li key={i} style={{display:"flex",alignItems:"center",flexDirection:"row"}}>
              <a href={href}  rel="noopener noreferrer">
                {label}
              </a>
              <span style={{display:"flex",justifyContent:"center",color:"#888"}}><svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="currentColor"
  height="18"
  width="18"
  className="ac11Icon absolute-center ac11collapsibleClosed"
>
  <path fill="none" d="M0 0h24v24H0V0z"></path>
  <path d="M9.41 16.59L13.83 12l-4.42-4.59L10 6l6 6-6 6-1.59-1.41z"></path>
</svg></span>
            </li>
          ))}
      </ul>
      {linksArray.length > maxLinksToShow && (
        <div
          className="toggle-button"
          onClick={() => setShowMore((prev) => !prev)}
        >
          {showMore ? "Less" : "More"}
          <span style={{display:"flex",justifyContent:"center"}}>
              {showMore ? ( <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="20"
                    width="20"
                    className="ac11Icon absolute-center ac11collapsibleClosed"
                  >
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path d="M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z"></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="20"
                    width="20"
                    className="ac11Icon absolute-center ac11collapsibleOpen"
                  >
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                    <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                  </svg>
                  )}
            </span>
        </div>
      )}
    </div>
  );
}

export default PersonalFinance;
