// import React from 'react';
// import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin, FaGithub, FaInstagram, FaTelegramPlane } from 'react-icons/fa';
// import { FaXTwitter,FaThreads } from "react-icons/fa6";
// import '../Footer.css';
// import { Link } from 'react-router-dom';
// import { Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';

// const Footer = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const isLaptop = useMediaQuery(theme.breakpoints.up('lg'));

//   const getWidth = () => {
//     if (isMobile) return '40%';
//     if (isLaptop) return '10%';
//     return '15%'; // Default width for other screen sizes
//   };
//   return (
//     <footer className="footer-distributed">
//       <div className="footer-left">
//         {/* <h3>Company<span>logo</span></h3> */}
//         {/* <p className="footer-company-name" style={{fontSize:"15px",color:"#ccc",marginBottom:"30px",width:"100%"}}><strong>Disclaimer: </strong>The financial information provided in this app is for educational and informational purposes only. It should not be considered as advice to buy or sell securities, invest in IPOs, or as a guide for making any investment decisions. We are not SEBI-registered analysts. Before making any investment decisions, users should consult a qualified financial advisor. The information in the app reflects data available at the time of publication and market perceptions. By using this app, you agree to our terms and conditions.</p> */}
//         <Typography
//             variant="h6"
//             noWrap
//             component={Link}
//             to="/"
//             // sx={{
//             //   mr: 2,
//             //   display: { xs: 'none', lg: 'flex' },
//             //   fontFamily: 'monospace',
//             //   fontWeight: 700,
//             //   letterSpacing: '.3rem',
//             //   color: 'inherit',
//             //   textDecoration: 'none',
//             // }}
//           >
//             <img src='/IPOtank.png' alt='IPO TANK Logo'   style={{ width: getWidth() }}   />
//           </Typography>
//         <p className="footer-links" style={{fontSize:"14.5px"}}>
//           <Link to="/" className="link-1">Home</Link>
//           <Link to="/current-ipo">Current IPO</Link>
//           <Link to="/upcoming-ipo">Upcoming IPO</Link>
//           <Link to="/recently-listed-ipo">Recently Listed IPO</Link>
//           <Link to="/closed-ipo">Closed IPO</Link>
//           <Link to="/ipo-news">News</Link>
//           <Link to="/ipo-calendar">IPO Calendar</Link>
//           <Link to="/buyback-calendar">Buyback Calendar</Link>
//           <Link to="/rights-issue-calendar">Rights Issues Calendar</Link>
//           <Link to="/ipo-gmp">GMP</Link>
//           <Link to="/ipo-performance">Performance</Link>
//           <Link to="/brokers">Brokers</Link>
//           <Link to="/buybacks">Buyback</Link>
//           <Link to="/rights-issues">Rights Issues</Link>
//           <Link to="/AboutUs">About Us</Link>
//           <Link to="/PrivacyPolicy">Privacy Policy</Link>
//           <Link to="/terms-conditions">Terms & Conditions</Link>
//           <Link to="/support-faqs">Support & FAQs</Link>
//           <Link to="/ipo-investor-categories">IPO Investor Categories</Link>
//           {/* <Link to="/DisclaimerAndPrivacy">Disclaimer And Privacy</Link> */}
//           <Link to="/ContactUs">Contact Us</Link>
//         </p>
//         <p className="footer-company-name" style={{fontSize:"15px",color:"#ccc"}}>All Rights Reserved.</p>

//         {/* <p>Follow Us on Insta<img src='/instagram_scanner.png' alt='IPO TANK Logo'   style={{ width:"100px",height:"100px",backgroundColor:"#ccc",borderRadius:"8px" }}   /></p> */}
//         <p className="footer-company-name" style={{fontSize:"14px",color:"#ccc",marginBottom:"30px",width:"100%",marginTop:"15px"}}><strong>Disclaimer: </strong>The financial information provided in this app is for educational and informational purposes only. It should not be considered as advice to buy or sell securities, invest in IPOs, or as a guide for making any investment decisions. We are not SEBI-registered analysts. Before making any investment decisions, users should consult a qualified financial advisor. The information in the app reflects data available at the time of publication and market perceptions. By using this app, you agree to our terms and conditions.</p>
       
//        <div className="footer-icons" style={{marginTop:"-13px"}}>
//        <p className="footer-company-about" style={{marginBottom:"-8px"}}>
//           <span style={{borderBottom:"1px solid #ff7847",paddingBottom:"5px"}}>Follow Us</span>
//         </p>
//         <a href="https://www.instagram.com/ipo.tank/" target="_blank" style={{fontSize:"24px"}}><FaInstagram/></a>
//         <a href="https://t.me/ipotank" target="_blank" style={{fontSize:"24px"}}><FaTelegramPlane/></a>
//         <a href="https://x.com/ipotank" target="_blank" style={{fontSize:"24px"}}><FaXTwitter/></a>
//         <a href="https://www.threads.net/@ipo.tank" target="_blank" style={{fontSize:"24px"}}><FaThreads /></a>
//         {/* <a href="https://www.instagram.com/ipo.tank/" target="_blank" style={{fontSize:"24px"}}><FaInstagram/></a>
//         <a href="https://www.instagram.com/ipo.tank/" target="_blank" style={{fontSize:"24px"}}><FaInstagram/></a> */}
//         </div>
//         {/* <div className="footer-icons" style={{display:"flex",alignItems:"center",justifyContent:"center",justifyItems:"center"}}>
//           <div>
//         <a href="mailto:ipotank@gmail.com" style={{fontSize:"14px"}}><FaEnvelope/></a></div>
//           <div>support@company.com</div>
//         </div> */}
//       </div>

//       {/* <div className="footer-center" > */}
//         {/* <div className="footer-icons" style={{display:"flex",justifyItems:"center"}}>
//           <a href="#" style={{fontSize:"15px"}}><FaMapMarkerAlt/></a>
//           <p><span>444 S. Cedros Ave</span> Solana Beach, California</p>
//         </div>
//         <div className="footer-icons"style={{display:"flex",justifyItems:"center",marginTop:"20px"}}>
//         <a href="#" style={{fontSize:"15px"}}><FaPhone/></a>
//           <p>+1.555.555.5555</p>
//         </div> */}
//         {/* <div className="footer-icons" style={{display:"flex",alignItems:"center"}}>
//         <a href="mailto:ipotank@gmail.com" style={{fontSize:"14px"}}><FaEnvelope/></a>
//           <p>support@company.com</p>
//         </div> */}
//       {/* </div> */}

//       {/* <div className="footer-right">
//         <p className="footer-company-about">
//           <span>About the company</span>
//           Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce euismod convallis velit, eu auctor lacus vehicula sit amet.
//         </p>
//         <div className="footer-icons">
//           <a href="#"><FaFacebook/></a>
//           <a href="#"><FaTwitter/></a>
//           <a href="#"><FaLinkedin/></a>
//           <a href="#"><FaGithub/></a>
//         </div>
//       </div> */}
//     </footer>
//   );
// }

// export default Footer;



import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Link as MuiLink,
} from '@mui/material';
// import { 
//   FaInstagram, 
//   FaTelegramPlane, 
//   FaXTwitter, 
//   FaThreads 
// } from 'react-icons/fa6';
import {  FaInstagram, FaTelegramPlane } from 'react-icons/fa';
import { FaXTwitter,FaThreads } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
    {/* <Helmet>
    <title>{`IPO List ${new Date().getFullYear()} | IPO List (Mainline and SME) | New IPO ${new Date().getFullYear()}`}</title>
    <meta name="description" content={`List of IPO in ${new Date().getFullYear()} in India. IPO tank for upcoming IPO details, GMP Grey Market Premium, Review, Performance, Allotment Status, and Live Subscription.`} />
    <meta name="keywords" content="initial public offering, IPO, IPOs, upcoming IPOs, current IPOs, closed IPOs, recently listed IPOs, IPO dates, IPO lot sizes, new IPOs, IPO prices, IPO subscriptions, IPO allotment, IPOtank, IPO tank" />
    
    <meta name="google-adsense-account" content="ca-pub-6833465353669475"/>
  
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6833465353669475"
       crossorigin="anonymous"></script>
  
  
  <meta property="og:url" content="https://www.ipotank.com/" /> <meta property="og:type" content="website" /> <meta property="og:title" content={`IPO List ${new Date().getFullYear()} | IPO List (Mainline and SME) | New IPO ${new Date().getFullYear()}`} /> <meta property="og:description" content={`List of IPO in ${new Date().getFullYear()} in India. IPO tank for upcoming IPO details, GMP Grey Market Premium, Review, Performance, Allotment Status, and Live Subscription.`} />
  </Helmet> */}
    <Box
      component="footer"
      sx={{
        backgroundColor: '#555',
        color: '#ccc',
        py: 4,
        px: 2,
        textAlign: isMobile ? 'center' : 'left',
      }}
    >
      <Grid container spacing={4} justifyContent="space-between">
        {/* Logo Section */}
        <Grid item xs={12} sm={4} lg={3}>
          <Box>
            <MuiLink component={Link} to="/">
              <img
                src="/IPOtank.png"
                alt="IPO TANK Logo"
                style={{ width: isMobile ? '40%' : '20%' }}
              />
            </MuiLink>
          </Box>
          <Typography variant="body2" sx={{ mt: 2 }}>
            <strong>Disclaimer:</strong> The financial information provided in
            this app is for educational and informational purposes only. It
            should not be considered as investment advice. Consult a qualified
            financial advisor for decisions.
          </Typography>
        </Grid>

        {/* Links Section */}
        {/* <Grid item xs={12} sm={8} lg={6}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {[
              { to: '/', label: 'IPO' },
              // { to: '/current-ipo', label: 'Current IPO' },
              // { to: '/upcoming-ipo', label: 'Upcoming IPO' },
              // { to: '/recently-listed-ipo', label: 'Recently Listed IPO' },
              // { to: '/closed-ipo', label: 'Closed IPO' },
              // { to: '/ipo-news', label: 'News' },
              // { to: '/ipo-calendar', label: 'IPO Calendar' },
              // { to: '/buyback-calendar', label: 'Buyback Calendar' },
              // { to: '/rights-issue-calendar', label: 'Rights Issues Calendar' },
              // { to: '/ipo-gmp', label: 'GMP' },
              // { to: '/ipo-performance', label: 'Performance' },
              { to: '/brokers', label: 'Brokers' },
              { to: '/buybacks', label: 'Buyback' },
              { to: '/rights-issues', label: 'Rights Issues' },
              { to: '/AboutUs', label: 'About Us' },
              { to: '/PrivacyPolicy', label: 'Privacy Policy' },
              { to: '/terms-conditions', label: 'Terms & Conditions' },
              { to: '/support-faqs', label: 'Support & FAQs' },
              { to: '/ipo-investor-categories', label: 'IPO Investor Categories' },
              { to: '/ContactUs', label: 'Contact Us' },
            ].map((item, index) => (
              <MuiLink
                key={index}
                component={Link}
                to={item.to}
                sx={{
                  textDecoration: 'none',
                  color: '#ff7847',
                  fontSize: '14px',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {item.label}
              </MuiLink>
            ))}
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={8} lg={6}>
  <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row', md: 'column' }, gap: { xs: 4, sm: 8, md: 4 } , flexWrap: 'wrap',justifyContent:"center"}}>
    {/* Products Section */}
    <Box>
      {/* <Box  sx={{ fontSize: '18px',  mb: 2 }}>
        Products
      </Box> */}
      <Typography  gutterBottom style={{fontSize:"18px",marginBottom:"10px",textAlign:"left"}}>
      Products
          </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, flexWrap: 'wrap', gap: 2 }}>
        {[
          { to: '/', label: 'IPO' },
          { to: '/brokers', label: 'Brokers' },
          { to: '/buybacks', label: 'Buyback' },
          { to: '/rights-issues', label: 'Rights Issues' },
          { to: '/p', label: 'Personal Finance' },
          { to: '/p/calculators', label: 'Financial Calculators' },
        ].map((item, index) => (
          <MuiLink
            key={index}
            component={Link}
            to={item.to}
            sx={{
              textAlign:"left",
              textDecoration: 'none',
              color: '#ff7847',
              fontSize: '14px',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            {item.label}
          </MuiLink>
        ))}
      </Box>
    </Box>

    {/* Legal Section */}
    <Box>
      {/* <Box  sx={{ fontSize: '18px',  mb: 2 }}>
        Legal
      </Box> */}
      <Typography  gutterBottom style={{fontSize:"18px",marginBottom:"10px",textAlign:"left"}}>
      Legal
          </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, flexWrap: 'wrap', gap: 2 }}>
        {[
          { to: '/AboutUs', label: 'About Us' },
          { to: '/PrivacyPolicy', label: 'Privacy Policy' },
          { to: '/terms-conditions', label: 'Terms & Conditions' },
          { to: '/support-faqs', label: 'Support & FAQs' },
          { to: '/ipo-investor-categories', label: 'IPO Investor Categories' },
          // { to: '/p/aadhaar-card', label: 'Aadhaar Card' },
          { to: '/ContactUs', label: 'Contact Us' },
        ].map((item, index) => (
          <MuiLink
            key={index}
            component={Link}
            to={item.to}
            sx={{
              textAlign:"left",
              textDecoration: 'none',
              color: '#ff7847',
              fontSize: '14px',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            {item.label}
          </MuiLink>
        ))}
      </Box>
    </Box>
  </Box>
</Grid>


        {/* Social Media Section */}
        <Grid item xs={12} lg={3}>
          <Typography  gutterBottom style={{fontSize:"18px",marginBottom:"10px"}}>
            Follow Us
          </Typography>
          {/* <Box sx={{ display: 'flex', gap: 2, justifyContent: isMobile ? 'center' : 'flex-start' }}>
            <IconButton
              href="https://www.instagram.com/ipo.tank/"
              target="_blank"
              sx={{ color: '#ff7847' }}
            >
              <FaInstagram />
            </IconButton>
            <IconButton
              href="https://t.me/ipotank"
              target="_blank"
              sx={{ color: '#ff7847' }}
            >
              <FaTelegramPlane />
            </IconButton>
            <IconButton
              href="https://x.com/ipotank"
              target="_blank"
              sx={{ color: '#ff7847' }}
            >
              <FaXTwitter />
            </IconButton>
            <IconButton
              href="https://www.threads.net/@ipo.tank"
              target="_blank"
              sx={{ color: '#ff7847' }}
            >
              <FaThreads />
            </IconButton>
          </Box> */}
          <Box
  sx={{
    display: 'flex',
    gap: 2,
    justifyContent: isMobile ? 'center' : 'flex-start',
  }}
>
  <IconButton
    href="https://www.instagram.com/ipo.tank/"
    target="_blank"
    sx={{
      color: 'white',
      backgroundColor: 'black',
      '&:hover': {
        color: '#ff7847',
      },
    }}
  >
    <FaInstagram />
  </IconButton>
  <IconButton
    href="https://t.me/ipotank"
    target="_blank"
    sx={{
      color: 'white',
      backgroundColor: 'black',
      '&:hover': {
        color: '#ff7847',
      },
    }}
  >
    <FaTelegramPlane />
  </IconButton>
  <IconButton
    href="https://x.com/ipotank"
    target="_blank"
    sx={{
      color: 'white',
      backgroundColor: 'black',
      '&:hover': {
        color: '#ff7847',
      },
    }}
  >
    <FaXTwitter />
  </IconButton>
  <IconButton
    href="https://www.threads.net/@ipo.tank"
    target="_blank"
    sx={{
      color: 'white',
      backgroundColor: 'black',
      '&:hover': {
        color: '#ff7847',
      },
    }}
  >
    <FaThreads />
  </IconButton>
</Box>

        </Grid>
      </Grid>
      <Typography
        variant="body2"
        sx={{ mt: 4, textAlign: 'center', color: '#888' }}
      >
        © {new Date().getFullYear()} All Rights Reserved.
      </Typography>
    </Box>
    </>
  );
};

export default Footer;



// import React, { useState } from 'react';
// import {
//   Box,
//   Grid,
//   Typography,
//   IconButton,
//   Link as MuiLink,
//   Menu,
//   MenuItem,
// } from '@mui/material';
// import { FaInstagram, FaTelegramPlane } from 'react-icons/fa';
// import { FaXTwitter, FaThreads } from 'react-icons/fa6';
// import { Link } from 'react-router-dom';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';

// const Footer = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [subPages, setSubPages] = useState([]);

//   // Handle mouse enter for navigation with subpages
//   const handleMouseEnter = (event, pages) => {
//     setAnchorEl(event.currentTarget);
//     setSubPages(pages || []);
//   };

//   // Handle mouse leave
//   const handleMouseLeave = () => {
//     setAnchorEl(null);
//     setSubPages([]);
//   };

//   const navItems = [
//     { name: 'Home', link: '/' },
//     {
//       name: 'IPO',
//       subPages: [
//         { name: 'Current IPO', link: '/current-ipo' },
//         { name: 'Closed IPO', link: '/closed-ipo' },
//         { name: 'Upcoming IPO', link: '/upcoming-ipo' },
//         { name: 'Recently Listed IPO', link: '/recently-listed-ipo' },
//       ],
//     },
//     { name: 'IPO News', link: '/ipo-news' },
//     {
//       name: 'Calendar',
//       subPages: [
//         { name: 'IPO Calendar', link: '/ipo-calendar' },
//         { name: 'Buyback Calendar', link: '/buyback-calendar' },
//         { name: 'Rights Issues Calendar', link: '/rights-issue-calendar' },
//       ],
//     },
//     { name: 'IPO GMP', link: '/ipo-gmp' },
//     { name: 'IPO Performance', link: '/ipo-performance' },
//     { name: 'Brokers', link: '/brokers' },
//     { name: 'Buyback', link: '/buybacks' },
//     { name: 'Rights Issues', link: '/rights-issues' },
//     {
//       name: 'Legal',
//       subPages: [
//         { name: 'About Us', link: '/AboutUs' },
//         { name: 'Contact Us', link: '/ContactUs' },
//         { name: 'Privacy Policy', link: '/PrivacyPolicy' },
//         { name: 'Terms & Conditions', link: '/terms-conditions' },
//         { name: 'Support & FAQs', link: '/support-faqs' },
//         { name: 'IPO Investor Categories', link: '/ipo-investor-categories' },
//       ],
//     },
//   ];

//   return (
//     <Box
//       component="footer"
//       sx={{
//         backgroundColor: '#555',
//         color: '#ccc',
//         py: 4,
//         px: 2,
//         textAlign: isMobile ? 'center' : 'left',
//       }}
//     >
//       <Grid container spacing={4} justifyContent="space-between">
//         {/* Logo Section */}
//         <Grid item xs={12} sm={4} lg={3}>
//           <Box>
//             <MuiLink component={Link} to="/">
//               <img
//                 src="/IPOtank.png"
//                 alt="IPO TANK Logo"
//                 style={{ width: isMobile ? '40%' : '20%' }}
//               />
//             </MuiLink>
//           </Box>
//           <Typography variant="body2" sx={{ mt: 2 }}>
//             <strong>Disclaimer:</strong> The financial information provided in
//             this app is for educational and informational purposes only. It
//             should not be considered as investment advice. Consult a qualified
//             financial advisor for decisions.
//           </Typography>
//         </Grid>

//         {/* Links Section */}
//         <Grid item xs={12} sm={8} lg={6}>
//           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
//             {navItems.map((item, index) => (
//               <Box
//                 key={index}
//                 onMouseEnter={(e) => handleMouseEnter(e, item.subPages || [])}
//                 onMouseLeave={handleMouseLeave}
//                 sx={{ position: 'relative' }}
//               >
//                 <MuiLink
//                   component={Link}
//                   to={item.link || '#'}
//                   sx={{
//                     textDecoration: 'none',
//                     color: '#ff7847',
//                     fontSize: '14px',
//                     '&:hover': { textDecoration: 'underline' },
//                   }}
//                 >
//                   {item.name}
//                 </MuiLink>
//               </Box>
//             ))}
//           </Box>
//         </Grid>

//         {/* Social Media Section */}
//         <Grid item xs={12} lg={3}>
//           <Typography variant="h6" gutterBottom>
//             Follow Us
//           </Typography>
//           <Box sx={{ display: 'flex', gap: 2, justifyContent: isMobile ? 'center' : 'flex-start' }}>
//             <IconButton href="https://www.instagram.com/ipo.tank/" target="_blank" sx={{ color: '#ff7847' }}>
//               <FaInstagram />
//             </IconButton>
//             <IconButton href="https://t.me/ipotank" target="_blank" sx={{ color: '#ff7847' }}>
//               <FaTelegramPlane />
//             </IconButton>
//             <IconButton href="https://x.com/ipotank" target="_blank" sx={{ color: '#ff7847' }}>
//               <FaXTwitter />
//             </IconButton>
//             <IconButton href="https://www.threads.net/@ipo.tank" target="_blank" sx={{ color: '#ff7847' }}>
//               <FaThreads />
//             </IconButton>
//           </Box>
//         </Grid>
//       </Grid>

//       {/* Menu for SubPages */}
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMouseLeave}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'center',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'center',
//         }}
//       >
//         {subPages.map((page, idx) => (
//           <MenuItem key={idx} component={Link} to={page.link}>
//             {page.name}
//           </MenuItem>
//         ))}
//       </Menu>

//       <Typography variant="body2" sx={{ mt: 4, textAlign: 'center', color: '#888' }}>
//         © {new Date().getFullYear()} All Rights Reserved.
//       </Typography>
//     </Box>
//   );
// };

// export default Footer;





// import React, { memo } from 'react';
// import {
//   Box,
//   Grid,
//   Typography,
//   IconButton,
//   Link as MuiLink,
// } from '@mui/material';
// import { FaInstagram, FaTelegramPlane } from 'react-icons/fa';
// import { FaXTwitter, FaThreads } from 'react-icons/fa6';
// import { Link } from 'react-router-dom';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';

// const Footer = memo(() => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const socialLinks = [
//     { href: 'https://www.instagram.com/ipo.tank/', icon: <FaInstagram />, label: 'Instagram' },
//     { href: 'https://t.me/ipotank', icon: <FaTelegramPlane />, label: 'Telegram' },
//     { href: 'https://x.com/ipotank', icon: <FaXTwitter />, label: 'Twitter' },
//     { href: 'https://www.threads.net/@ipo.tank', icon: <FaThreads />, label: 'Threads' },
//   ];

//   const footerLinks = [
//     { to: '/', label: 'Home' },
//     { to: '/current-ipo', label: 'Current IPO' },
//     { to: '/upcoming-ipo', label: 'Upcoming IPO' },
//     { to: '/recently-listed-ipo', label: 'Recently Listed IPO' },
//     { to: '/closed-ipo', label: 'Closed IPO' },
//     { to: '/ipo-news', label: 'News' },
//     { to: '/ipo-calendar', label: 'IPO Calendar' },
//     { to: '/buyback-calendar', label: 'Buyback Calendar' },
//     { to: '/rights-issue-calendar', label: 'Rights Issues Calendar' },
//     { to: '/ipo-gmp', label: 'GMP' },
//     { to: '/ipo-performance', label: 'Performance' },
//     { to: '/brokers', label: 'Brokers' },
//     { to: '/buybacks', label: 'Buyback' },
//     { to: '/rights-issues', label: 'Rights Issues' },
//     { to: '/AboutUs', label: 'About Us' },
//     { to: '/PrivacyPolicy', label: 'Privacy Policy' },
//     { to: '/terms-conditions', label: 'Terms & Conditions' },
//     { to: '/support-faqs', label: 'Support & FAQs' },
//     { to: '/ipo-investor-categories', label: 'IPO Investor Categories' },
//     { to: '/ContactUs', label: 'Contact Us' },
//   ];

//   return (
//     <Box
//       component="footer"
//       sx={{
//         backgroundColor: '#444',
//         color: '#f5f5f5',
//         py: 4,
//         px: isMobile ? 2 : 6,
//         textAlign: isMobile ? 'center' : 'left',
//         borderTop: '2px solid #ff7847',
//       }}
//     >
//       <Grid container spacing={4}>
//         {/* Logo Section */}
//         <Grid item xs={12} sm={4} lg={3}>
//           <MuiLink component={Link} to="/">
//             <img
//               src="/IPOtank.png"
//               alt="IPO TANK Logo"
//               style={{ width: isMobile ? '50%' : '30%' }}
//               loading="lazy"
//             />
//           </MuiLink>
//           <Typography variant="body2" sx={{ mt: 2, color: '#ccc' }}>
//             <strong>Disclaimer:</strong> The financial information provided in
//             this app is for educational and informational purposes only. It
//             should not be considered as investment advice. Consult a qualified
//             financial advisor for decisions.
//           </Typography>
//         </Grid>

//         {/* Links Section */}
//         <Grid item xs={12} sm={8} lg={6}>
//           <Box
//             sx={{
//               display: 'grid',
//               gridTemplateColumns: `repeat(auto-fit, minmax(120px, 1fr))`,
//               gap: 2,
//             }}
//           >
//             {footerLinks.map((link, index) => (
//               <MuiLink
//                 key={index}
//                 component={Link}
//                 to={link.to}
//                 sx={{
//                   textDecoration: 'none',
//                   color: '#ff7847',
//                   fontSize: '14px',
//                   '&:hover': { textDecoration: 'underline' },
//                 }}
//               >
//                 {link.label}
//               </MuiLink>
//             ))}
//           </Box>
//         </Grid>

//         {/* Social Media Section */}
//         <Grid item xs={12} lg={3}>
//           <Typography variant="h6" gutterBottom>
//             Follow Us
//           </Typography>
//           <Box
//             sx={{
//               display: 'flex',
//               gap: 2,
//               justifyContent: isMobile ? 'center' : 'flex-start',
//             }}
//           >
//             {socialLinks.map((social, index) => (
//               <IconButton
//                 key={index}
//                 href={social.href}
//                 target="_blank"
//                 sx={{
//                   color: '#ff7847',
//                   transition: 'transform 0.2s',
//                   '&:hover': { transform: 'scale(1.2)' },
//                 }}
//                 aria-label={`Visit us on ${social.label}`}
//               >
//                 {social.icon}
//               </IconButton>
//             ))}
//           </Box>
//         </Grid>
//       </Grid>

//       <Typography
//         variant="body2"
//         sx={{
//           mt: 4,
//           textAlign: 'center',
//           color: '#888',
//         }}
//       >
//         © {new Date().getFullYear()} IPO Tank. All Rights Reserved.
//       </Typography>
//     </Box>
//   );
// });

// export default Footer;
