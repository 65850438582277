import * as React from 'react';
// import { styled } from '@mui/material/styles';

// import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { Avatar} from '@mui/material';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
// import React, { useState, useEffect } from 'react';
import { SlArrowRight } from "react-icons/sl";
// import { Pix } from '@mui/icons-material';
import {  Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { MdSubscriptions } from "react-icons/md";
import '../banner.css';
import { useRef } from 'react';
// import Slider from 'react-slick';
// import { Link } from 'react-router-dom';
import ConfirmDialog from './ConfirmDialog';
import IPOStatusComponent from './IPOStatusComponent';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css'; // Import Swiper styles
// import { Navigation, Pagination } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css'; // For older versions, if you use v9 or later, import from 'swiper/swiper.css'
// import { Navigation, Pagination } from 'swiper';
import FaqComponent from './FaqComponent'; 
import { hostInfo , token } from '../server';
// Use the Swiper styles from 'swiper/swiper-bundle.min.css' or 'swiper/swiper.css' for version v9+
// import 'swiper/swiper-bundle.min.css'; 

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#ff7847",
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));



// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // // hide last border
//   // '&:last-child td, &:last-child th': {
//   //   border: 0,
//   // },
// }));

// const IPOComponent = ({ targetString, ipoPerformanceData }) => {
//   // Split the target string into words
//   const targetWords = new Set(targetString.toLowerCase().split(' '));

//   // Define a function to count matching words
//   const countMatchingWords = (companyName) => {
//     const companyWords = new Set(companyName.toLowerCase().split(' '));
//     const intersection = [...targetWords].filter(word => companyWords.has(word));
//     return intersection.length;
//   };

//   // Map through the data and calculate matching words
//   const matchingRows = ipoPerformanceData.map(row => ({
//     ...row,
//     Matching_Words: countMatchingWords(row.Company_Name)
//   }));

//   // Find the row with the maximum number of matching words
//   const maxMatchingWords = Math.max(...matchingRows.map(row => row.Matching_Words));
  
//   if (maxMatchingWords >= targetWords.size - 1) {
//     const bestMatchRow = matchingRows.find(row => row.Matching_Words === maxMatchingWords);
//     const Company_Name = bestMatchRow.Company_Name;
//     const Current_Price = bestMatchRow.Current_Price;
//     const Profit_Loss = bestMatchRow.Profit_Loss;
//   } else {
//     const Company_Name = null;
//     const Current_Price = null;
//     const Profit_Loss = null;
//   }

//   return (
//     <>
//       {Profit_Loss !== ('' || null) && (<><Link to={`/ipo/${targetString.toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// // style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
// >
// <div style={{marginRight:"5px"}}>Closing Price:</div>{Profit_Loss < 0 ? (<div style={{color:'red'}}> {Current_Price} {Profit_Loss}</div>):(<div style={{color:'green'}}> {Current_Price} {Profit_Loss}</div>)}
// </div></Link></>)}
//     </>
//   );
// };


const IPOComponent = ({ targetString, ipoPerformanceData, row11, row12 }) => {
  // console.log(ipoPerformanceData);
  
  // const targetWords = new Set((targetString || '').toLowerCase().split(' '));

  // const countMatchingWords = (companyName) => {
  //   const companyWords = new Set(companyName.toLowerCase().split(' '));
  //   const intersection = [...targetWords].filter(word => companyWords.has(word));
  //   return intersection.length;
  // };

  // const matchingRows = ipoPerformanceData.map(row => ({
  //   ...row,
  //   Matching_Words: countMatchingWords(row.Company_Name)
  // }));

  // const maxMatchingWords = matchingRows.length > 0 ? Math.max(...matchingRows.map(row => row.Matching_Words)) : 0;
  
  // const bestMatchRow = matchingRows.find(row => row.Matching_Words === maxMatchingWords) || {};

  // const { Company_Name, Current_Price, Profit_Loss } = bestMatchRow;

  // console.log("ipoPerformanceData", targetString);
  
  const targetWords = new Set((targetString || '').toLowerCase().split(' '));

  const countMissingWords = (companyName) => {
    const companyWords = new Set(companyName?.toLowerCase().split(' '));
    const missingWords = [...targetWords].filter(word => !companyWords.has(word));
    return missingWords.length;
  };

  const matchingRows = ipoPerformanceData?.map(row => {
    const missingWordsCount = countMissingWords(row.Company_Name);
    return {
      ...row,
      Missing_Words: missingWordsCount
    };
  });

  // Find the row with the least missing words, but allow at most one missing word
  const bestMatchRow = matchingRows?.find(row => row.Missing_Words <= 1) || {};

  const { Company_Name, Current_Price, Profit_Loss } = bestMatchRow.Missing_Words > 1 ? {} : bestMatchRow;

  // console.log("ipoPerformanceData", Company_Name, Current_Price, Profit_Loss);

  return (
    <>
      {Profit_Loss && (Profit_Loss !== '' || Profit_Loss !== null) ? (
        <Link to={`/ipo/${targetString.toLowerCase().replace(/ /g, '-')}`} style={{ textDecoration: "none" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "3px 10px", fontSize: "14px", color: "#888", textAlign: "start" }}>
            <div style={{ marginRight: "5px",color: "#888" }}>Closing Price:</div>
            <div style={{ color: Profit_Loss.replace("%",'') < 0 ? 'red' : 'green' }}>
              {Current_Price.replace("â¹","₹")} (<span>{Profit_Loss}</span>)
            </div>
          </div>
        </Link>
      ):(row11 !== ('' || null) && (<><Link to={`/ipo/${targetString.toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
      // style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
      >
      <div style={{marginRight:"5px",color: "#888"}}>Exp. Premium:</div>{row11 < 0 ? (<div style={{color:'red'}}> {row11} {row12.split(' ')[1]}</div>):(<div style={{color:'green'}}> {row11} {row12.split(' ')[1]}</div>)}
      </div></Link></>))}
    </>
  );
};





export default function Home() {

  const [allIPOsPerformance, setAllIPOsPerformance] = useState([]);
  // const [IPOs, setIPOs] = useState([]);
  // const [blogs, setBlogs] = useState([]);
  const [currentIPOs, setCurrentIPOs] = useState([]);
  // console.log(allIPOs)
  const [closedIPOs, setClosedIPOs] = useState([]);
  const [upcomingIPOs, setUpcomingIPOs] = useState([]);
  // console.log(upcomingIPOs)
  const [recentlyListedIPOs, setRecentlyListedIPOs] = useState([]);

  useEffect(() => {
    fetch(`${hostInfo}/all_current_ipos`, {
      method: 'GET', // Specify the HTTP method
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      }
    })
    .then(response => {
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    }).then((data)=>{setCurrentIPOs(data.current_ipos);})
   
  }, []);

  
  useEffect(() => {
    fetch(`${hostInfo}/all_closed_ipos`, {
      method: 'GET', // Specify the HTTP method
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      }
    })
    .then(response => {
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    }).then((data)=>{setClosedIPOs(data.closed_ipos);})
   
  }, []);

  useEffect(() => {
    fetch(`${hostInfo}/all_upcoming_ipos`, {
      method: 'GET', // Specify the HTTP method
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      }
    })
    .then(response => {
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    }).then((data)=>{setUpcomingIPOs(data.upcoming_ipos);})
   
  }, []);


  
  useEffect(() => {
    fetch(`${hostInfo}/all_recently_listed_ipos`, {
      method: 'POST', // Specify the HTTP method
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      },
      body: JSON.stringify({ limit: 10 })
    })
    .then(response => {
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    }).then((data)=>{setRecentlyListedIPOs(data.recently_listed_ipos);})
   
  }, []);

  useEffect(() => {
    fetch(`${hostInfo}/performance`, {
      method: 'GET', // Specify the HTTP method
      headers: {
        'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        'Content-Type': 'application/json', // Set the Content-Type if necessary
      }
    })
    .then(response => {
      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }
      return response.json();
    }).then((data)=>{setAllIPOsPerformance(data);})
  }, []);



  

  console.log(recentlyListedIPOs);
  const currentIpoRef = useRef(null);
  const closedIpoRef = useRef(null);
  const upcomingIpoRef = useRef(null);
  const listedIpoRef = useRef(null);
  const parentDivRef = useRef(null);
  const [activeButton, setActiveButton] = useState(null); 



  // const faqData = [
  //   {
  //     question: "What is an IPO?",
  //     answer: "An Initial Public Offering (IPO) is the process through which a private company offers its shares to the public for the first time. This allows the company to raise capital from public investors."
  //   },
  //   {
  //     question: "How can I apply for an IPO?",
  //     answer: "You can apply for an IPO through your stockbroker or by using online platforms provided by financial institutions. You will need to have a Demat account and sufficient funds to apply for the shares."
  //   },
  //   {
  //     question: "What is the difference between a fixed price and a book building IPO?",
  //     answer: "In a fixed price IPO, the company sets a specific price at which its shares will be offered. In a book building IPO, the company provides a price range, and investors bid within that range. The final price is determined based on the bids received."
  //   },
  //   {
  //     question: "What are the risks associated with investing in an IPO?",
  //     answer: "Investing in an IPO carries risks such as market volatility, overvaluation, and the company's performance post-listing. It's important to thoroughly research the company and consider your risk tolerance before investing."
  //   },
  //   {
  //     question: "What is the IPO allotment process?",
  //     answer: "Once the IPO subscription period closes, the company and its underwriters allocate shares to investors based on demand and availability. The allotment status is typically announced within a week after the IPO closes."
  //   },
  //   {
  //     question: "How can I check my IPO allotment status?",
  //     answer: "You can check your IPO allotment status on the registrar's website by entering your PAN number or application number. The allotment status is usually available a few days after the IPO closes."
  //   },
  //   {
  //     question: "What is the lock-in period in an IPO?",
  //     answer: "The lock-in period is the time frame during which certain shareholders, like company promoters or early investors, are restricted from selling their shares. This period typically lasts for a few months after the IPO."
  //   },
  //   {
  //     question: "What happens on the IPO listing day?",
  //     answer: "On the IPO listing day, the company's shares begin trading on the stock exchange. The listing price is determined based on market demand and can be higher or lower than the issue price."
  //   },
  //   {
  //     question: "What is the grey market in IPOs?",
  //     answer: "The grey market is an unofficial market where IPO shares are traded before they are officially listed on the stock exchange. The grey market premium (GMP) can provide an indication of the market's sentiment towards the IPO."
  //   },
  //   {
  //     question: "Can I sell my IPO shares on the listing day?",
  //     answer: "Yes, you can sell your IPO shares on the listing day if they are allotted to you. The shares will trade on the stock exchange, and you can sell them at the market price."
  //   }
  // ];


  // useEffect(() => {
  //   let timeoutId;
  
  //   const handleScroll = () => {
  //     clearTimeout(timeoutId); // Clear previous timeout
  //     timeoutId = setTimeout(() => {
  //       const viewportHeight = window.innerHeight;
  
  //       // Get the heights and positions of each tab
  //       const tab1Top = currentIpoRef.current ? currentIpoRef.current.getBoundingClientRect().top : Infinity;
  //       const tab2Top = closedIpoRef.current ? closedIpoRef.current.getBoundingClientRect().top : Infinity;
  //       const tab3Top = upcomingIpoRef.current ? upcomingIpoRef.current.getBoundingClientRect().top : Infinity;
  //       const tab4Top = listedIpoRef.current ? listedIpoRef.current.getBoundingClientRect().top : Infinity;
  //       // const tab5Top = tab5Ref.current ? tab5Ref.current.getBoundingClientRect().top : Infinity;
  
  //       // const tab1Bottom = currentIpoRef.current ? currentIpoRef.current.getBoundingClientRect().bottom : Infinity;
  //       // const tab2Bottom = closedIpoRef.current ? closedIpoRef.current.getBoundingClientRect().bottom : Infinity;
  //       // const tab3Bottom = upcomingIpoRef.current ? upcomingIpoRef.current.getBoundingClientRect().bottom : Infinity;
  //       // const tab4Bottom = listedIpoRef.current ? listedIpoRef.current.getBoundingClientRect().bottom : Infinity;
  //       // // const tab5Bottom = tab5Ref.current ? tab5Ref.current.getBoundingClientRect().bottom : Infinity;
  
  //       const fortyPercentViewportHeight = viewportHeight * 0.4; // 40% of viewport height

  //     // Check if the tab is visible in the viewport (either from the top or bottom 40%)
  //     const isTabVisible = (tabTop, tabBottom) => {
  //       return (
  //         (tabTop >= 0 && tabTop < viewportHeight) || // Top is visible
  //         (tabBottom >= fortyPercentViewportHeight && tabBottom < viewportHeight) // Bottom is 40% visible
  //       );
  //     };
  
  //       if (isTabVisible(tab1Top)) {
  //         setActiveButton("currentIpo");
  //       } else if (isTabVisible(tab2Top)) {
  //         setActiveButton("closedIpo");
  //       } else if (isTabVisible(tab3Top)) {
  //         setActiveButton("upcomingIpo");
  //       } else if (isTabVisible(tab4Top)) {
  //         setActiveButton("listedIpo");
  //       } 
  //     }, 100); // Adjust the timeout as needed
  //   };
  
  //   window.addEventListener('scroll', handleScroll);
  
  //   return () => {
  //     clearTimeout(timeoutId); // Clean up timeout on unmount
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [currentIpoRef, closedIpoRef, upcomingIpoRef, listedIpoRef]);
  

  useEffect(() => {
    // Scroll to the current IPO section by default when the component mounts
    if (parentDivRef.current && currentIpoRef.current) {
      const parentTop = parentDivRef.current.getBoundingClientRect().top;
      const elementTop = currentIpoRef.current.getBoundingClientRect().top;
      const scrollTop = elementTop - parentTop + parentDivRef.current.scrollTop;

      parentDivRef.current.scrollTo({
        top: scrollTop,
        behavior: 'smooth'
      });
      setActiveButton('currentIpo');
    }
  }, []); // Empty dependency array means this runs only once, on mount

  // const handleScrollToSection = (ref, buttonName) => {
  //   if (parentDivRef.current && ref.current) {
  //     const parentTop = parentDivRef.current.getBoundingClientRect().top;
  //     const elementTop = ref.current.getBoundingClientRect().top;
  //     const scrollTop = elementTop - parentTop + parentDivRef.current.scrollTop;

  //     parentDivRef.current.scrollTo({
  //       top: scrollTop,
  //       behavior: 'smooth'
  //     });
  //     setActiveButton(buttonName);
  //   }
  // };

  useEffect(() => {
    let timeoutId;
  
    const handleScroll = () => {
      clearTimeout(timeoutId); // Clear previous timeout
      timeoutId = setTimeout(() => {
        const viewportHeight = window.innerHeight;
  
        // Get the heights and positions of each tab
        const tab1Top = currentIpoRef.current ? currentIpoRef.current.getBoundingClientRect().top : Infinity;
        const tab2Top = closedIpoRef.current ? closedIpoRef.current.getBoundingClientRect().top : Infinity;
        const tab3Top = upcomingIpoRef.current ? upcomingIpoRef.current.getBoundingClientRect().top : Infinity;
        const tab4Top = listedIpoRef.current ? listedIpoRef.current.getBoundingClientRect().top : Infinity;
        // const tab5Top = tab5Ref.current ? tab5Ref.current.getBoundingClientRect().top : Infinity;
  
        const tab1Bottom = currentIpoRef.current ? currentIpoRef.current.getBoundingClientRect().bottom : Infinity;
        const tab2Bottom = closedIpoRef.current ? closedIpoRef.current.getBoundingClientRect().bottom : Infinity;
        const tab3Bottom = upcomingIpoRef.current ? upcomingIpoRef.current.getBoundingClientRect().bottom : Infinity;
        const tab4Bottom = listedIpoRef.current ? listedIpoRef.current.getBoundingClientRect().bottom : Infinity;
        // const tab5Bottom = tab5Ref.current ? tab5Ref.current.getBoundingClientRect().bottom : Infinity;
  
        const fortyPercentViewportHeight = viewportHeight * 0.4; // 40% of viewport height

      // Check if the tab is visible in the viewport (either from the top or bottom 40%)
      const isTabVisible = (tabTop, tabBottom) => {
        return (
          (tabTop >= 0 && tabTop < viewportHeight) || // Top is visible
          (tabBottom >= fortyPercentViewportHeight && tabBottom < viewportHeight) // Bottom is 40% visible
        );
      };
  
        if (isTabVisible(tab1Top, tab1Bottom)) {
          setActiveButton('currentIpo');
        } else if (isTabVisible(tab2Top, tab2Bottom)) {
          setActiveButton('closedIpo');
        } else if (isTabVisible(tab3Top, tab3Bottom)) {
          setActiveButton('upcomingIpo');
        } else if (isTabVisible(tab4Top, tab4Bottom)) {
          setActiveButton('listedIpo');
        } 
      }, 100); // Adjust the timeout as needed
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      clearTimeout(timeoutId); // Clean up timeout on unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ currentIpoRef ,closedIpoRef ,upcomingIpoRef , listedIpoRef ]);


  

  const handleScrollToSection = (ref, buttonName) => {
    if (parentDivRef.current && ref.current) {

      let element = null;

    if (ref && ref.current) {
      element = ref.current;
    } 

    if (element) {
      // let offset = 0; // Default offset
      // if (className === 'laptop-view') {
      //   offset = 165; // Offset for laptop-view
      // } else if (className === 'phone-view') {
      //   offset = 80; // Offset for phone-view
      // }

      window.scrollTo({
        top: element.offsetTop - 88,
        behavior: 'smooth'
      });
    }

        // const parentScrollTop = parentDivRef.current.scrollTop;
        // const parentOffsetTop = parentDivRef.current.offsetTop;
        // const elementOffsetTop = ref.current.offsetTop;

        // // Scroll to the exact position of the element relative to the parent
        // const scrollTop = elementOffsetTop - parentOffsetTop;

        // parentDivRef.current.scrollTo({
        //     top: scrollTop,
        //     behavior: 'smooth'
        // });

        setActiveButton(buttonName); // Update active button
    }
};



  // const handleScrollToSection = (ref) => {
  //   if (parentDivRef.current && ref.current) {
  //     const parentTop = parentDivRef.current.getBoundingClientRect().top;
  //     const elementTop = ref.current.getBoundingClientRect().top;
  //     const scrollTop = elementTop - parentTop + parentDivRef.current.scrollTop;

  //     parentDivRef.current.scrollTo({
  //       top: scrollTop,
  //       behavior: 'smooth'
  //     });
  //   }
  // };
  // const handleScrollToCurrent = () => {
  //   parentDivRef.current.scrollTo({
  //     top: currentIpoRef.current.offsetTop - parentDivRef.current.offsetTop,
  //     behavior: 'smooth'
  //   });
  // };

  // const handleScrollToClosed = () => {
  //   parentDivRef.current.scrollTo({
  //     top: closedIpoRef.current.offsetTop - parentDivRef.current.offsetTop,
  //     behavior: 'smooth'
  //   });
  // };

  // const handleScrollToUpcoming = () => {
  //   parentDivRef.current.scrollTo({
  //     top: upcomingIpoRef.current.offsetTop - parentDivRef.current.offsetTop,
  //     behavior: 'smooth'
  //   });
  // };

  // const handleScrollToListed = () => {
  //   parentDivRef.current.scrollTo({
  //     top: listedIpoRef.current.offsetTop - parentDivRef.current.offsetTop,
  //     behavior: 'smooth'
  //   });
  // };
  
  const navigate = useNavigate();


  const handleViewAllClick = (destination) => {
    navigate(destination);
};

// const handleIPOClick = (data) => {
//   navigate(`/ipo/${data.toLowerCase().replace(/ /g, '-')}`);
// };


  // function formatDate(dateString) {
  //   const dateObject = new Date(dateString);
  //   const options = { month: "short", day: "2-digit", year: "numeric" };
  //   return dateObject.toLocaleDateString("en-US", options);
  // }

  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    // { image: '../ipotank_banner_1.jpg', title: 'IPO - Initial Public Offerings', subtitle: 'The financial markets are on the move', description: 'Invest in companies listing on the Indian exchanges with an IPO' },
    { image: '../ipotank_banner_10.jpg', title: 'IPO - Initial Public Offerings', subtitle: 'The financial markets are on the move', description: 'Invest in companies listing on the Indian exchanges with an IPO' },
    // { image: '../ipotank_banner_8.jpg', title: 'IPO - Initial Public Offerings', subtitle: 'The financial markets are on the move', description: 'Invest in companies listing on the Indian exchanges with an IPO' },
     { image: '../ipotank_banner_11.jpg', title: 'IPO - Initial Public Offerings', subtitle: 'The financial markets are on the move', description: 'Invest in companies listing on the Indian exchanges with an IPO' },
    // { image: '../ipotank_banner_4.jpg', title: 'IPO - Initial Public Offerings', subtitle: 'The financial markets are on the move', description: 'Invest in companies listing on the Indian exchanges with an IPO' },
    // { image: '../ipotank_banner_5.jpg', title: 'IPO - Initial Public Offerings', subtitle: 'The financial markets are on the move', description: 'Invest in companies listing on the Indian exchanges with an IPO' },
    // { image: '../ipotank_banner_6.jpg', title: 'IPO - Initial Public Offerings', subtitle: 'The financial markets are on the move', description: 'Invest in companies listing on the Indian exchanges with an IPO' },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  // const prevSlide = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  // };


  // const handleClick = (value) => {
  //   window.open(value, '_blank');
  // };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [externalUrl, setExternalUrl] = useState('');

  const handleClick = (value) => {
      setExternalUrl(value);
      setDialogOpen(true);
  };

  const handleDialogClose = () => {
      setDialogOpen(false);
      setExternalUrl('');
  };

  const handleDialogConfirm = () => {
      setDialogOpen(false);
      window.open(externalUrl, '_blank');
  };


  return (
  <>
  <Helmet>
  <title>{`IPO List ${new Date().getFullYear()} | IPO List (Mainline and SME) | New IPO ${new Date().getFullYear()}`}</title>
  <meta name="description" content={`List of IPO in ${new Date().getFullYear()} in India. IPO tank for upcoming IPO details, GMP Grey Market Premium, Review, Performance, Allotment Status, and Live Subscription.`} />
  <meta name="keywords" content="initial public offering, IPO, IPOs, upcoming IPOs, current IPOs, closed IPOs, recently listed IPOs, IPO dates, IPO lot sizes, new IPOs, IPO prices, IPO subscriptions, IPO allotment, IPOtank, IPO tank" />
  
  <meta name="google-adsense-account" content="ca-pub-6833465353669475"/>

  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6833465353669475"
     crossorigin="anonymous"></script>


<meta property="og:url" content="https://www.ipotank.com/" /> <meta property="og:type" content="website" /> <meta property="og:title" content={`IPO List ${new Date().getFullYear()} | IPO List (Mainline and SME) | New IPO ${new Date().getFullYear()}`} /> <meta property="og:description" content={`List of IPO in ${new Date().getFullYear()} in India. IPO tank for upcoming IPO details, GMP Grey Market Premium, Review, Performance, Allotment Status, and Live Subscription.`} />
</Helmet>

{/* <div className="carousel_header">
      <div id="home_carousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div role="banner" className="container-xxl">
              <img
                src="https://ipoji.com/public/assets/adds/banner_1.png"
                alt="IPO Ji"
                width="auto"
                height="auto"
                loading="lazy"
                className="d-block"
              />
            </div>
          </div>
          <div className="carousel-item">
            <div role="banner" className="container-xxl">
              <img
                src="https://ipoji.com/public/assets/adds/banner_2.png"
                alt="Brokers - IPO Ji"
                width="auto"
                height="auto"
                loading="lazy"
                className="d-block"
              />
            </div>
          </div>
          <div className="carousel-item">
            <div role="banner" className="container-xxl">
              <img
                src="https://ipoji.com/public/assets/adds/banner_3.png"
                alt="Buy backs - IPO Ji"
                width="auto"
                height="auto"
                loading="lazy"
                className="d-block"
              />
            </div>
          </div>
        </div>
        <div className="carousel-indicators align-items-center homeCarouselIndicators">
          <button
            type="button"
            data-bs-target="#home_carousel"
            data-bs-slide-to="0"
            aria-label="Slide 1"
            className="active"
            aria-current="true"
          ></button>
          <button
            type="button"
            data-bs-target="#home_carousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#home_carousel"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#home_carousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#home_carousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div> */}

    {/* <img src={`../ipotank_banner_1.jpg`} /> */}

   {/* {deviceInfo === "app" ? null : ( <div className="carousel">
      <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="carousel-item" key={index} style={{ backgroundImage: `url(${slide.image})` }}>
            
          </div>
        ))}
      </div>
      <button className="arrow prev" onClick={prevSlide}>❮</button>
      <button className="arrow next" onClick={nextSlide}>❯</button>
    </div>)} */}

    {/* <div style={{padding:"10px 0 10px 0"}}>
     
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'left'}}>
          <div style={{ width: '90%', maxWidth: '1200px' }}>
    
      <br/>
     
      <h2 style={{marginTop:"-20px",paddingTop:"25px"}}>IPO - Initial Public Offerings</h2>
      <h4 style={{marginTop:"30px"}}>The financial markets are on the move</h4>
      <label style={{marginTop:"-10px"}}>Invest in companies listing on the Indian exchanges with an IPO</label></div></div></div> */}
      <div style={{ padding:"10px 0 40px 0",background:"linear-gradient(90deg, rgba(251,196,150,1) 0%, rgba(196,199,199,1) 52%, rgba(255,255,255,1) 100%)"}}>
     
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'left'}}>
          <div style={{ width: '90%', maxWidth: '1200px' }}>
    
      <br/>
     
      <h1 style={{marginTop:"-20px",paddingTop:"35px"}}>IPO - Initial Public Offerings</h1>
      <h3 style={{marginTop:"30px"}}>The financial markets are on the move</h3>
      <label style={{marginTop:"-10px"}}>Invest in companies listing on the Indian exchanges with an IPO</label></div></div></div>
       {/* <div style={{ textAlign: 'left' ,display: "flex",alignItems: "left", gap: "15px", color:"#666",paddingTop:"25px"}} >
        <Link to={`/`}  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}} >
          <p>
            Home
          </p>
        </Link>
        <p style={{color:"black",fontWeight: "bold"}}><SlArrowRight  style={{height:"12px", width:"12px"}}/></p>
      
        <Link to={`/`} onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" ,textDecoration:"none"}}>
          <p>IPO</p>
        </Link>
       
      </div> */}
      <br/>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    {/* <div style={{ width: '90%', maxWidth: '1200px',minWidth:"100px",marginBottom:"-40px" }}> */}
    <div className="button-container" style={{ width: '90%', maxWidth: '1200px',minWidth:"100px"}}>
    <button   className={`button ${activeButton === 'currentIpo' ? 'active' : ''}`}
                onClick={() => handleScrollToSection(currentIpoRef, 'currentIpo')}>Current IPO</button>
          <button className={`button ${activeButton === 'closedIpo' ? 'active' : ''}`}
                onClick={() => handleScrollToSection(closedIpoRef, 'closedIpo')}>Closed IPO</button>
          <button  className={`button ${activeButton === 'upcomingIpo' ? 'active' : ''}`}
                onClick={() => handleScrollToSection(upcomingIpoRef, 'upcomingIpo')}>Upcoming IPO</button>
          <button  className={`button ${activeButton === 'listedIpo' ? 'active' : ''}`}
                onClick={() => handleScrollToSection(listedIpoRef, 'listedIpo')}>Recently Listed IPO</button>
        </div>
        
        <div ref={parentDivRef} 
       
         style={{
          width: '90%', maxWidth: '1200px',minWidth:"100px",
          // maxWidth:"1200px",
          // minWidth:"100px",
          // width:"100%",
          // // overflowY: 'scroll', // Allows scrolling
          // // maxHeight: '1680px',
          
          marginTop: '10px',
        
          scrollbarWidth: 'none', // For Firefox
          msOverflowStyle: 'none', // For Internet Explorer and Edge


          userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text' 
        }}
        // style={{ overflowY: 'auto', maxHeight: '1600px',marginTop:"20px" }} 
        >
          {(currentIPOs?.length > 0 || closedIPOs?.length > 0 || upcomingIPOs?.length > 0 || recentlyListedIPOs?.length > 0) ? (<>
        {/* <div ref={currentIpoRef}> */}
    <h2 ref={currentIpoRef} style={{ marginTop:"0px",textAlign: 'left' ,fontSize: "18px",fontWeight: "bold",color:"white",padding:"10px 20px",boxShadow: 'inset 550px 0px 400px -70px #faac7b',borderRadius:"10px"}}>Current IPO</h2>
    {/* ,boxShadow:"0px 0px 20px rgba(0, 0, 0, 0.5),boxShadow: 'inset 0px 0px 100px 0px #ff7847', */}
    {currentIPOs?.length > 0 ? (
       <div >
       {currentIPOs.slice(0, 12).map((row) => (
        <>
        <div  style={{marginBottom:"15px",border:"1px solid #dfdede",borderRadius:"10px",boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)"}}>
        
             <div style={{ display: 'flex', flexWrap: 'wrap',padding:"10px 10px" }}>
              {/* <div style={{borderBottom:"1px solid #00A877" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
  <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}>
  <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
    <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px", width: "50px" }} />
    {row[1]}
  </div>
  <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #00A877",borderRadius:"5px",backgroundColor:"#00A877",textDecoration:"none"}}>SME</label> :""}</div>
  <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
  
  </div>
</Link>
</Typography>
              </div> */}
              <div style={{borderBottom:"1px solid #00A877" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", color: "#666", textDecoration: "none" }}>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',flexWrap:"wrap" }}>
 {/* <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}> */}
 <div style={{ display: "flex" ,alignItems:"center" }}>
 <Avatar  style={{ margin: "0px 10px", boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',   userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>{row[1].charAt(0).toUpperCase()}</Avatar> {/* Display the first character inside an avatar */}
 <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
    
        {/* <Avatar  style={{ margin: "0px 10px", userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text'  }}>{row[1].charAt(0).toUpperCase()}</Avatar>  */}
        {row[1]}

   {/* <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="" style={{ marginRight: "0px", width: "50px",userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text'  }} />
   {row[1]} */}
 </div>
 <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #00A877",borderRadius:"5px",backgroundColor:"#00A877",textDecoration:"none"}}>SME</label> :<label style={{color:"#107356",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #bbe3d8",borderRadius:"5px",backgroundColor:"#bbe3d8",textDecoration:"none"}}>Mainboard</label>}</div>
 <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
 </div>
 {/* <div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div> */}
</div>
</Link>
</Typography>
{/* <div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
{row[11] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)}
<div style={{marginLeft:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
      /></div></div> */}
       <div style={{display:"flex",alignItems:"center",justifyContent: 'space-between',flexWrap:"wrap"}}>
<div style={{marginLeft:"10px",marginRight:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
        listingDate={new Date(row[4])} 
      /></div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',flexWrap:"wrap" }}>
{row[11] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)}<div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" style={{margin:"3px 10px"}} onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div></div>

      </div>
             </div>
              <div style={{display: 'flex', flexDirection: 'row' ,width:"100%", flexWrap: 'wrap', padding:"10px 10px",justifyContent:"space-between",gap:"12px"}}>
   <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
   <div >
   <label style={{color:"#888"}}>Open Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {(row[2] !== null) ?( <strong>
      {new Date(row[2]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[3]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
     </strong> */}
   </div>
    </div>
 
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
   
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Close Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[3] !== null) ?( <strong>
      {new Date(row[3]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[4]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Listing Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[5] !== null) ?( <strong>
      {new Date(row[5]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Price</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {/* {(row[5] !== '') ? (<strong>₹ {row[5].split('to')[1]}</strong>):<strong>------</strong>} */}
    {(row[5] !== '') ? (
  <strong>
    {row[5].includes('to') ? `₹ ${row[5].split('to')[1]}` : `₹ ${row[5]}`}
  </strong>
) : <strong>------</strong>}
     {/* <strong>
           
 ₹ {row[6]}
       </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[6] !== '') ? (<strong>₹ {row[6]} Cr</strong>):<strong>------</strong>}
     {/* <strong>
       
 ₹ {row[7]} Cr
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Lot Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[7] !== '') ? (<strong>{row[7]}</strong>):<strong>------</strong>}
     {/* <strong>
       {row[8]}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
     {row[9] !== null && row[9]?.includes('(') ? <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> : <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><div style={{color:"rgb(107, 128, 179)"}}>Applications</div></div></label>}
   {/* <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> */}
   <div style={{marginTop:"5px",height:"auto"}}>
   {/* {(row[9] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`}
      
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>} */}
     {(row[14] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {/* {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`} */}
      {/* {row[9].split(' ')[0].replace(/[^\d,]/g, '')} */}{JSON.parse(row[14])["Subscription (times)"]} times
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"90px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}} >Exchange</label>
   <div style={{marginTop:"5px",height:"auto"}}>
     <strong>
       {row[9]}
         </strong>
   </div>
    </div>
     </Typography>
     </div> */}
                 </div>
             </div>
             {/* </CardContent>
           </Card> */}
         {/* </Grid> */}
         </div>
         </>
       ))}
  <div style={{ display:"flex",alignItems:"center",justifyContent:"center"}} >
        {currentIPOs.length > 12 && (
    <div
        // style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
        style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                 border: "2px solid #666" ,maxWidth:"350px",width:"100%",marginBottom:"15px"}} 
            onMouseEnter={(e) => {
               e.target.style.borderColor = '#ff7847';
               e.target.style.color = '#ff7847';
             }} 
             onMouseLeave={(e) => {
               e.target.style.borderColor = '#666';
               e.target.style.color = '#666';
             }}
        onClick={() => handleViewAllClick('/current-ipo')}
        // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = ''}
    >
        View All
    </div>
)} </div>
       </div>
//     <TableContainer component={Paper} >
//       <Table sx={{ minWidth: 350 }} aria-label="customized table">
//       {/* <caption
//             style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
//             onClick={() => handleViewAllClick('/current-ipo')}
//             onMouseEnter={(e) => e.target.style.color = '#ff7847'}
//             onMouseLeave={(e) => e.target.style.color = ''}
//           >
//             View All
//           </caption> */}
//           {currentIPOs.length > 8 && (
//     <caption
//         style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
//         onClick={() => handleViewAllClick('/current-ipo')}
//         onMouseEnter={(e) => e.target.style.color = '#ff7847'}
//         onMouseLeave={(e) => e.target.style.color = ''}
//     >
//         View All
//     </caption>
// )}

//         <TableHead>
//         <TableRow>
//                 <StyledTableCell>Issuer Company</StyledTableCell>
//                 <StyledTableCell align="right">Open Date</StyledTableCell>
//                 <StyledTableCell align="right">Close Date</StyledTableCell>
//                 <StyledTableCell align="right">Listing Date</StyledTableCell>
//                 {/* <StyledTableCell align="right">Lead Manager</StyledTableCell>
//                 <StyledTableCell align="right">Market Maker</StyledTableCell> */}
//                 <StyledTableCell align="right">
//                   Issue Price (Rs)
//                 </StyledTableCell>
//                 <StyledTableCell align="right">
//                   Issue Size (Rs Cr.)
//                 </StyledTableCell>
//                 <StyledTableCell align="right">Lot Size</StyledTableCell>
//                 <StyledTableCell align="right"> Exchange</StyledTableCell>
//             </TableRow>
//         </TableHead>
//         <TableBody>
//           {currentIPOs.slice(0, 8).map((row) => (
//             <StyledTableRow key={row[0]}>
//               <StyledTableCell component="th" scope="row"  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
//         onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" }} onClick={() => handleIPOClick(row[1])} >
//                        <div style={{ display: "flex", alignItems: "center" }}>
//                         <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px" ,width:"50px"}} />
//                         {row[1]}
//                     </div>
//               </StyledTableCell>
//               <StyledTableCell align="right">{formatDate(row[3])}</StyledTableCell>
//               <StyledTableCell align="right">{formatDate(row[4])}</StyledTableCell>
//               <StyledTableCell align="right">{formatDate(row[5])}</StyledTableCell>
//               <StyledTableCell align="right">{row[6]}</StyledTableCell>
//               <StyledTableCell align="right">{row[7]}</StyledTableCell>
//               <StyledTableCell align="right">{row[8]}</StyledTableCell>
//               <StyledTableCell align="right">{row[9]}</StyledTableCell>
//             </StyledTableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
    ) : (
      <p style={{ textAlign: 'left',marginBottom:"30px",marginLeft:"20px"}}>No Current IPO available.</p>
  )}
  {/* </div> */}
    {/* <br/> */}
    {/* <div ref={closedIpoRef}> */}
    <h2 ref={closedIpoRef} style={{ textAlign: 'left'  ,fontSize: "18px",fontWeight: "bold",color:"white",padding:"10px 20px",boxShadow: 'inset 550px 0px 400px -70px #faac7b',borderRadius:"10px"}}>Closed IPO</h2>
    {closedIPOs?.length > 0 ? (
      <div >
      {closedIPOs.slice(0, 8).map((row) => (
       <>
       <div  style={{marginBottom:"15px",border:"1px solid #dfdede",borderRadius:"10px",boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)"}}>
       
            <div style={{ display: 'flex', flexWrap: 'wrap',padding:"10px 10px" }}>
             <div style={{borderBottom:"1px solid #FF91A4" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", color: "#666", textDecoration: "none" }}>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',flexWrap:"wrap" }}>
 {/* <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}> */}
 <div style={{ display: "flex" ,alignItems:"center" }}>
 <Avatar  style={{ margin: "0px 10px", boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',   userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>{row[1].charAt(0).toUpperCase()}</Avatar> {/* Display the first character inside an avatar */}
 <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
   {/* <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="" style={{ marginRight: "0px", width: "50px",userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text'  }} /> */}
   {row[1]}
 </div>
 <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #FF91A4",borderRadius:"5px",backgroundColor:"#FF91A4",textDecoration:"none"}}>SME</label> :<label style={{color:"#f5506b",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #fbe7ea",borderRadius:"5px",backgroundColor:"#fbe7ea",textDecoration:"none"}}>Mainboard</label>}</div>
 <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
 </div>
 {/* <div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div> */}
</div>
</Link>
</Typography>
{/* <div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
{row[11] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)}
<div style={{marginLeft:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
      /></div></div> */}
       <div style={{display:"flex",alignItems:"center",justifyContent: 'space-between',flexWrap:"wrap"}}>
<div style={{marginLeft:"10px",marginRight:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
        listingDate={new Date(row[4])} 
      /></div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',flexWrap:"wrap" }}>
{row[11] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)}<div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" style={{margin:"3px 10px"}} onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div></div>

      </div>
             </div>
             <div style={{display: 'flex', flexDirection: 'row' ,width:"100%", flexWrap: 'wrap', padding:"10px 10px",justifyContent:"space-between",gap:"12px"}}>
   <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
   <div >
   <label style={{color:"#888"}}>Open Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {(row[2] !== null) ?( <strong>
      {new Date(row[2]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[3]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
     </strong> */}
   </div>
    </div>
 
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
   
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Close Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[3] !== null) ?( <strong>
      {new Date(row[3]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[4]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Listing Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[5] !== null) ?( <strong>
      {new Date(row[5]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     
   </div>
    </div>
     </Typography>
     </div> */}
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Price</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {/* {(row[5] !== '') ? (<strong>₹ {row[5].split('to')[1]}</strong>):<strong>------</strong>} */}
    {(row[5] !== '') ? (
  <strong>
    {row[5].includes('to') ? `₹ ${row[5].split('to')[1]}` : `₹ ${row[5]}`}
  </strong>
) : <strong>------</strong>}
     {/* <strong>
           
 ₹ {row[6]}
       </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[6] !== '') ? (<strong>₹ {row[6]} Cr</strong>):<strong>------</strong>}
     {/* <strong>
       
 ₹ {row[7]} Cr
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Lot Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[7] !== '') ? (<strong>{row[7]}</strong>):<strong>------</strong>}
     {/* <strong>
       {row[8]}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20}/><div>Subs</div></div></label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[9] !== null) ?( <strong>
      {row[9].split(' ')[0].replace(/[^\d,]/g, '')}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
       <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
     {row[9] !== null && row[9]?.includes('(') ? <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> : <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><div style={{color:"rgb(107, 128, 179)"}}>Applications</div></div></label>}
   {/* <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> */}
   <div style={{marginTop:"5px",height:"auto"}}>
   {/* {(row[9] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`}
      
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>} */}
      {(row[14] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {/* {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`} */}
      {/* {row[9].split(' ')[0].replace(/[^\d,]/g, '')} */}{JSON.parse(row[14])["Subscription (times)"]} times
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"90px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}} >Exchange</label>
   <div style={{marginTop:"5px",height:"auto"}}>
     <strong>
       {row[9]}
         </strong>
   </div>
    </div>
     </Typography>
     </div> */}
                 </div>
            </div>
            {/* </CardContent>
          </Card> */}
        {/* </Grid> */}
        </div>
        </>
      ))}
 <div style={{ display:"flex",alignItems:"center",justifyContent:"center"}} >
       {closedIPOs?.length > 8 && (
   <div
       // style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
       style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                border: "2px solid #666" ,maxWidth:"350px",width:"100%",marginBottom:"15px"}} 
           onMouseEnter={(e) => {
              e.target.style.borderColor = '#ff7847';
              e.target.style.color = '#ff7847';
            }} 
            onMouseLeave={(e) => {
              e.target.style.borderColor = '#666';
              e.target.style.color = '#666';
            }}
       onClick={() => handleViewAllClick('/closed-ipo')}
       // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
       // onMouseLeave={(e) => e.target.style.color = ''}
   >
       View All
   </div>
)} </div>
      </div>
    // <TableContainer component={Paper} >
    //   <Table sx={{ minWidth: 350 }} aria-label="customized table">
    //   {closedIPOs.length > 8 && (
    //   <caption
    //         style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
    //         onClick={() => handleViewAllClick('/closed-ipo')}
    //         onMouseEnter={(e) => e.target.style.color = '#ff7847'}
    //         onMouseLeave={(e) => e.target.style.color = ''}
    //       >
    //         View All
    //       </caption>)}
    //       <TableHead>
    //     <TableRow>
    //             <StyledTableCell>Issuer Company</StyledTableCell>
    //             <StyledTableCell align="right">Open Date</StyledTableCell>
    //             <StyledTableCell align="right">Close Date</StyledTableCell>
    //             <StyledTableCell align="right">Listing Date</StyledTableCell>
    //             {/* <StyledTableCell align="right">Lead Manager</StyledTableCell>
    //             <StyledTableCell align="right">Market Maker</StyledTableCell> */}
    //             <StyledTableCell align="right">
    //               Issue Price (Rs)
    //             </StyledTableCell>
    //             <StyledTableCell align="right">
    //               Issue Size (Rs Cr.)
    //             </StyledTableCell>
    //             <StyledTableCell align="right">Lot Size</StyledTableCell>
    //             <StyledTableCell align="right"> Exchange</StyledTableCell>
    //         </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {closedIPOs.slice(0, 8).map((row) => (
    //         <StyledTableRow key={row[0]}>
    //           <StyledTableCell component="th" scope="row"  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
    //     onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" }} onClick={() => handleIPOClick(row[1])} >
    //             <div style={{ display: "flex", alignItems: "center" }}>
    //                     <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px" ,width:"50px"}} />
    //                     {row[1]}
    //                 </div>
                  
    //           </StyledTableCell>
    //           <StyledTableCell align="right">{formatDate(row[3])}</StyledTableCell>
    //           <StyledTableCell align="right">{formatDate(row[4])}</StyledTableCell>
    //           <StyledTableCell align="right">{formatDate(row[5])}</StyledTableCell>
    //           <StyledTableCell align="right">{row[6]}</StyledTableCell>
    //           <StyledTableCell align="right">{row[7]}</StyledTableCell>
    //           <StyledTableCell align="right">{row[8]}</StyledTableCell>
    //           <StyledTableCell align="right">{row[9]}</StyledTableCell>
    //         </StyledTableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
    ) : (
      <p style={{ textAlign: 'left',marginBottom:"30px",marginLeft:"20px"}}>No Closed IPO available.</p>
  )}
  {/* </div> */}
    {/* <br/> */}
    {/* <div ref={upcomingIpoRef}> */}
    <h2 ref={upcomingIpoRef} style={{ textAlign: 'left'  ,fontSize: "18px",fontWeight: "bold",color:"white",padding:"10px 20px",boxShadow: 'inset 550px 0px 400px -70px #faac7b',borderRadius:"10px"}}>Upcoming IPO</h2>
    {upcomingIPOs?.length > 0 ? (
       <div >
       {upcomingIPOs?.slice(0, 8).map((row) => (
        <>
        <div  style={{marginBottom:"15px",border:"1px solid #dfdede",borderRadius:"10px",boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)"}}>
        
             <div style={{ display: 'flex', flexWrap: 'wrap',padding:"10px 10px" }}>
              {/* <div style={{borderBottom:"1px solid #d3d2d2" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
  <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}>
  <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
    <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px", width: "50px" }} />
    {row[1]}
  </div>
  <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #bfbfbe",borderRadius:"5px",backgroundColor:"#bfbfbe",textDecoration:"none"}}>SME</label> :""}</div>
  <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
  </div>
</Link>
</Typography>
              </div> */}

              <div style={{borderBottom:"1px solid #d3d2d2" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", color: "#666", textDecoration: "none" }}>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',flexWrap:"wrap" }}>
 {/* <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}> */}
 <div style={{ display: "flex" ,alignItems:"center" }}>
 <Avatar  style={{ margin: "0px 10px", boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',   userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>{row[1].charAt(0).toUpperCase()}</Avatar> {/* Display the first character inside an avatar */}
 <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
   {/* <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="" style={{ marginRight: "0px", width: "50px" ,userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text' }} /> */}
   {row[1]}
 </div>
 <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #bfbfbe",borderRadius:"5px",backgroundColor:"#bfbfbe",textDecoration:"none"}}>SME</label> :<label style={{color:"#656464",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #e5e3e3",borderRadius:"5px",backgroundColor:"#e5e3e3",textDecoration:"none"}}>Mainboard</label>}</div>
 <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
 </div>
 {/* <div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div> */}
</div>
</Link>
</Typography>
{/* <div style={{display:"flex",alignItems:"center",flexWrap:"wrap"}}>
{row[11] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)}
<div style={{marginLeft:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
      /></div></div> */}
      <div style={{display:"flex",alignItems:"center",justifyContent: 'space-between',flexWrap:"wrap"}}>
<div style={{marginLeft:"10px",marginRight:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
        listingDate={new Date(row[4])} 
      /></div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',flexWrap:"wrap" }}>
{row[11] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Exp. Premium:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)}<div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" style={{margin:"3px 10px"}} onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div></div>

      </div>
             </div>
              <div style={{display: 'flex', flexDirection: 'row' ,width:"100%", flexWrap: 'wrap', padding:"10px 10px",justifyContent:"space-between",gap:"12px"}}>
   <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
   <div >
   <label style={{color:"#888"}}>Open Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {(row[2] !== null) ?( <strong>
      {new Date(row[2]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[3]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
     </strong> */}
   </div>
    </div>
 
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
   
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Close Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[3] !== null) ?( <strong>
      {new Date(row[3]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[4]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Listing Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[5] !== null) ?( <strong>
      {new Date(row[5]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     
   </div>
    </div>
     </Typography>
     </div> */}
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Price</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {/* {(row[5] !== '') ? (<strong>₹ {row[5].split('to')[1]}</strong>):<strong>------</strong>} */}
    {(row[5] !== '') ? (
  <strong>
    {row[5].includes('to') ? `₹ ${row[5].split('to')[1]}` : `₹ ${row[5]}`}
  </strong>
) : <strong>------</strong>}
     {/* <strong>
           
 ₹ {row[6]}
       </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[6] !== '') ? (<strong>₹ {row[6]} Cr</strong>):<strong>------</strong>}
     {/* <strong>
       
 ₹ {row[7]} Cr
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Lot Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[7] !== '') ? (<strong>{row[7]}</strong>):<strong>------</strong>}
     {/* <strong>
       {row[8]}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20}/><div>Subs</div></div></label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[9] !== null) ?( <strong>
      {row[9].split(' ')[0].replace(/[^\d,]/g, '')}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
       <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
     {row[9] !== null && row[9]?.includes('(') ? <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> : <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><div style={{color:"rgb(107, 128, 179)"}}>Applications</div></div></label>}
   {/* <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> */}
   <div style={{marginTop:"5px",height:"auto"}}>
   {/* {(row[9] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`}
      
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>} */}
      {(row[14] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {/* {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`} */}
      {/* {row[9].split(' ')[0].replace(/[^\d,]/g, '')} */}{JSON.parse(row[14])["Subscription (times)"]} times
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"90px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}} >Exchange</label>
   <div style={{marginTop:"5px",height:"auto"}}>
     <strong>
       {row[9]}
         </strong>
   </div>
    </div>
     </Typography>
     </div> */}
                 </div>
             </div>
             {/* </CardContent>
           </Card> */}
         {/* </Grid> */}
         </div>
         </>
       ))}
  <div style={{ display:"flex",alignItems:"center",justifyContent:"center"}} >
        {upcomingIPOs?.length > 8 && (
    <div
        // style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
        style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                 border: "2px solid #666" ,maxWidth:"350px",width:"100%",marginBottom:"15px"}} 
            onMouseEnter={(e) => {
               e.target.style.borderColor = '#ff7847';
               e.target.style.color = '#ff7847';
             }} 
             onMouseLeave={(e) => {
               e.target.style.borderColor = '#666';
               e.target.style.color = '#666';
             }}
        onClick={() => handleViewAllClick('/upcoming-ipo')}
        // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = ''}
    >
        View All
    </div>
    
)} </div> 
       </div>
    // <TableContainer component={Paper} >
    //   <Table sx={{ minWidth: 350 }} aria-label="customized table">
    //   {upcomingIPOs.length > 8 && (
    //   <caption
    //         style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
    //         onClick={() => handleViewAllClick('/upcoming-ipo')}
    //         onMouseEnter={(e) => e.target.style.color = '#ff7847'}
    //         onMouseLeave={(e) => e.target.style.color = ''}
    //       >
    //         View All
    //       </caption>)}
    //       <TableHead>
    //     <TableRow>
    //             <StyledTableCell>Issuer Company</StyledTableCell>
    //             <StyledTableCell align="right">Open Date</StyledTableCell>
    //             <StyledTableCell align="right">Close Date</StyledTableCell>
    //             <StyledTableCell align="right">Listing Date</StyledTableCell>
    //             {/* <StyledTableCell align="right">Lead Manager</StyledTableCell>
    //             <StyledTableCell align="right">Market Maker</StyledTableCell> */}
    //             <StyledTableCell align="right">
    //               Issue Price (Rs)
    //             </StyledTableCell>
    //             <StyledTableCell align="right">
    //               Issue Size (Rs Cr.)
    //             </StyledTableCell>
    //             <StyledTableCell align="right">Lot Size</StyledTableCell>
    //             <StyledTableCell align="right"> Exchange</StyledTableCell>
    //         </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {upcomingIPOs.slice(0, 8).map((row) => (
    //         <StyledTableRow key={row[0]}>
    //           <StyledTableCell component="th" scope="row"  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
    //     onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" }} onClick={() => handleIPOClick(row[1])} >
          
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //                     <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px" ,width:"50px"}} />
    //                     {row[1]}
    //                 </div>
               
    //           </StyledTableCell>
    //           <StyledTableCell align="right">{formatDate(row[3])}</StyledTableCell>
    //           <StyledTableCell align="right">{formatDate(row[4])}</StyledTableCell>
    //           <StyledTableCell align="right">{formatDate(row[5])}</StyledTableCell>
    //           <StyledTableCell align="right">{row[6]}</StyledTableCell>
    //           <StyledTableCell align="right">{row[7]}</StyledTableCell>
    //           <StyledTableCell align="right">{row[8]}</StyledTableCell>
    //           <StyledTableCell align="right">{row[9]}</StyledTableCell>
    //         </StyledTableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
    ) : (
      <p style={{ textAlign: 'left',marginBottom:"30px",marginLeft:"20px"}}>No Upcoming IPO available.</p>
  )}
  {/* </div> */}
  {/* <br/> */}
{/* <div ref={listedIpoRef}> */}
    <h2 ref={listedIpoRef} style={{ textAlign: 'left'  ,fontSize: "18px",fontWeight: "bold",color:"white",padding:"10px 20px",boxShadow: 'inset 550px 0px 400px -70px #faac7b',borderRadius:"10px"}}>Recently Listed IPO</h2>
    {recentlyListedIPOs?.length > 0 ? (
       <div style={{marginBottom:"20px"}}>
       {recentlyListedIPOs.slice(0, 8).map((row) => (
        <>
        <div  style={{marginBottom:"15px",border:"1px solid #dfdede",borderRadius:"10px",boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.2)"}}>
        
             <div style={{ display: 'flex', flexWrap: 'wrap',padding:"10px 10px" }}>
              {/* <div style={{borderBottom:"1px solid #20B2AA" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", cursor: 'pointer', color: "#666", textDecoration: "none" }}>
  <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}>
  <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
    <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px", width: "50px" }} />
    {row[1]}
  </div>
  <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #20B2AA",borderRadius:"5px",backgroundColor:"#20B2AA",textDecoration:"none"}}>SME</label> :""}</div>
  <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
  </div>
</Link>
</Typography>
              </div> */}
               <div style={{borderBottom:"1px solid #20B2AA" ,width:"100%",padding:"10px 0"}}>
<Typography  component="div">
<Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{fontSize:"15px", fontWeight: "bold", color: "#666", textDecoration: "none" }}>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',flexWrap:"wrap" }}>
 {/* <div style={{ display: "flex" ,alignItems:"center",cursor:"pointer" }}> */}
 <div style={{ display: "flex" ,alignItems:"center" }}>
 <Avatar  style={{ margin: "0px 10px", boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',   userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>{row[1].charAt(0).toUpperCase()}</Avatar> {/* Display the first character inside an avatar */}
 <div onMouseEnter={(e) => e.target.style.color = '#ff7847'} onMouseLeave={(e) => e.target.style.color = '#666'} style={{ display: "flex", alignItems: "center" }}>
   {/* <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="" style={{ marginRight: "0px", width: "50px",userSelect:'text', WebkitUserSelect: 'text', MozUserSelect: 'text', msUserSelect: 'text'  }} /> */}
   {row[1]}
 </div>
 <div  style={{color:"white",fontSize:"13px"}}>{row[8] && row[8].split(" ").pop() === "SME" ? <label style={{color:"white",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #20B2AA",borderRadius:"5px",backgroundColor:"#20B2AA",textDecoration:"none"}}>SME</label> :<label style={{color:"#25817c",fontWeight:"lighter",marginLeft:"5px",padding:"1px 10px",border:"1px solid #b4e7e5",borderRadius:"5px",backgroundColor:"#b4e7e5",textDecoration:"none"}}>Mainboard</label>}</div>
 <p style={{fontWeight: "bold",marginLeft:"5px"}}><SlArrowRight  style={{height:"13px", width:"13px",color:"#444",fontWeight:"bold"}}/></p>
 </div>
 {/* <div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div> */}
</div>
</Link>
</Typography>
<div style={{display:"flex",alignItems:"center",justifyContent: 'space-between',flexWrap:"wrap"}}>
<div style={{marginLeft:"10px",marginRight:"10px"}}>
<IPOStatusComponent
        openDate={new Date(row[2])} 
        closeDate={new Date(row[3])} 
        allotmentDate={new Date(row[13])} 
        isAllotmentOut={row[10] && row[10]} 
        ipoName={row[1].toLowerCase().replace(/ /g, '-')}
        listingDate={new Date(row[4])} 
      /></div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',flexWrap:"wrap" }}>
<IPOComponent  targetString={row[1]} ipoPerformanceData={allIPOsPerformance} row11={row[11]} row12={row[12]} /><div style={{ display: 'flex', alignItems: 'center' }}><Link to={`/`}  style={{textDecoration: "none" }}>
 {row[10] !== null && (<button className="animated-button" style={{margin:"3px 10px"}} onClick={() => handleClick(row[10] && row[10])}>Check Allotment</button>)}</Link></div></div>

      </div>
{/* {row[13] !== ('' || null) && (<><Link to={`/ipo/${row[1].toLowerCase().replace(/ /g, '-')}`}  style={{textDecoration: "none" }}><div style={{display:"flex",flexDirection:"row",alignItems:"center",padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}} 
// style={{padding:"3px 10px",fontSize:"14px",color:"#888",textAlign:"start"}}
>
<div style={{marginRight:"5px"}}>Closing Price:</div>{row[11] < 0 ? (<div style={{color:'red'}}> {row[11]} {row[12].split(' ')[1]}</div>):(<div style={{color:'green'}}> {row[11]} {row[12].split(' ')[1]}</div>)}
</div></Link></>)} */}

             </div>
              <div style={{display: 'flex', flexDirection: 'row' ,width:"100%", flexWrap: 'wrap', padding:"10px 10px",justifyContent:"space-between",gap:"12px"}}>
   <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
   <div >
   <label style={{color:"#888"}}>Open Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {(row[2] !== null) ?( <strong>
      {new Date(row[2]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[3]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
     </strong> */}
   </div>
    </div>
 
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
   
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Close Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[3] !== null) ?( <strong>
      {new Date(row[3]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
     {/* <strong>
       {formatDate(row[4]).split(',').map((part, i) => (
         <React.Fragment key={i}>
           {part}
         </React.Fragment>
       ))}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Listing Date</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[5] !== null) ?( <strong>
      {new Date(row[5]).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Price</label>
   <div style={{marginTop:"5px",height:"auto"}}>
    {/* {(row[5] !== '') ? (<strong>₹ {row[5].split('to')[1]}</strong>):<strong>------</strong>} */}
    {(row[5] !== '') ? (
  <strong>
    {row[5].includes('to') ? `₹ ${row[5].split('to')[1]}` : `₹ ${row[5]}`}
  </strong>
) : <strong>------</strong>}

     {/* <strong>
           
 ₹ {row[6]}
       </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Issue Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[6] !== '') ? (<strong>₹ {row[6]} Cr</strong>):<strong>------</strong>}
     {/* <strong>
       
 ₹ {row[7]} Cr
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     <div style={{ display: 'flex', flexDirection: 'column',minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}>Lot Size</label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[7] !== '') ? (<strong>{row[7]}</strong>):<strong>------</strong>}
     {/* <strong>
       {row[8]}
         </strong> */}
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20}/><div>Subs</div></div></label>
   <div style={{marginTop:"5px",height:"auto"}}>
   {(row[9] !== null) ?( <strong>
      {row[9].split(' ')[0].replace(/[^\d,]/g, '')}
     </strong>): <strong>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div> */}
       <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"100px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
     {row[9] !== null && row[9]?.includes('(') ? <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> : <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><div style={{color:"rgb(107, 128, 179)"}}>Applications</div></div></label>}
   {/* <label style={{color:"rgb(107, 128, 179)"}}><div style={{display:"flex",alignItems:"center",gap:"5px"}}><MdSubscriptions size={20} color='rgb(107, 128, 179)'/><div style={{color:"rgb(107, 128, 179)"}}>Subs</div></div></label> */}
   <div style={{marginTop:"5px",height:"auto"}}>
   {/* {(row[9] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`}
      
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>} */}

     {(row[14] !== null) ?( 
   <strong style={{color:"rgb(107, 128, 179)"}}>
     {/* {row[9].includes('(') ? `${row[9].split('(')[1].replace("(",'').replace(")",'')}` : `${row[9].split(' ')[0].replace(/[^\d,]/g, '')}`} */}
      {/* {row[9].split(' ')[0].replace(/[^\d,]/g, '')} */}{JSON.parse(row[14])["Subscription (times)"]} times
     </strong>
    ): <strong style={{color:"rgb(107, 128, 179)"}}>
       ------
     </strong>}
    
   </div>
    </div>
     </Typography>
     </div>
     {/* <div style={{ display: 'flex', flexDirection: 'column' ,minWidth:"50px",maxWidth:"90px",width:"100%"}}>
     <Typography variant="body2" color="textSecondary">
     <div >
   <label style={{color:"#888"}} >Exchange</label>
   <div style={{marginTop:"5px",height:"auto"}}>
     <strong>
       {row[9]}
         </strong>
   </div>
    </div>
     </Typography>
     </div> */}
                 </div>
             </div>
             {/* </CardContent>
           </Card> */}
         {/* </Grid> */}
         </div>
         </>
       ))}
       {/* <div style={{ display:"flex",alignItems:"center",justifyContent:"center"}} onClick={() => setShowAllRows(!showAllRows)}
          className="py-0 text-left pl-8">
        
           {showAllRows ? (
             <div className="flex " style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
             border: "2px solid #666" ,maxWidth:"350px",width:"100%"}} 
        onMouseEnter={(e) => {
           e.target.style.borderColor = '#ff7847';
           e.target.style.color = '#ff7847';
         }} 
         onMouseLeave={(e) => {
           e.target.style.borderColor = '#666';
           e.target.style.color = '#666';
         }}>
               Show Less 
              
             
             </div>
           ) : (
             <div className="flex " style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
             border: "2px solid #666" ,maxWidth:"350px",width:"100%"}} 
        onMouseEnter={(e) => {
           e.target.style.borderColor = '#ff7847';
           e.target.style.color = '#ff7847';
         }} 
         onMouseLeave={(e) => {
           e.target.style.borderColor = '#666';
           e.target.style.color = '#666';
         }}>
               View More
              
              
             </div>
           )}
        </div> */}
          <div style={{ display:"flex",alignItems:"center",justifyContent:"center"}} >
        {recentlyListedIPOs?.length > 8 && (
    <div
        // style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
        style={{ textAlign: "center", cursor: 'pointer',color:"#666",fontSize:"16px",padding:"5px 10px",borderRadius: "30px",
                 border: "2px solid #666" ,maxWidth:"350px",width:"100%"}} 
            onMouseEnter={(e) => {
               e.target.style.borderColor = '#ff7847';
               e.target.style.color = '#ff7847';
             }} 
             onMouseLeave={(e) => {
               e.target.style.borderColor = '#666';
               e.target.style.color = '#666';
             }}
        onClick={() => handleViewAllClick('/recently-listed-ipo')}
        // onMouseEnter={(e) => e.target.style.color = '#ff7847'}
        // onMouseLeave={(e) => e.target.style.color = ''}
    >
        View All
    </div>
)} </div>
       </div>
    // <TableContainer component={Paper} >
    //   <Table sx={{ minWidth: 350 }} aria-label="customized table">
    //   {recentlyListedIPOs.length > 8 && (
    //   <caption
    //         style={{ textAlign: "right", fontWeight: "bold", cursor: 'pointer' }}
    //         onClick={() => handleViewAllClick('/recently-listed-ipo')}
    //         onMouseEnter={(e) => e.target.style.color = '#ff7847'}
    //         onMouseLeave={(e) => e.target.style.color = ''}
    //       >
    //         View All
    //       </caption>)}
    //       <TableHead>
    //     <TableRow>
    //             <StyledTableCell>Issuer Company</StyledTableCell>
    //             <StyledTableCell align="right">Open Date</StyledTableCell>
    //             <StyledTableCell align="right">Close Date</StyledTableCell>
    //             <StyledTableCell align="right">Listing Date</StyledTableCell>
    //             {/* <StyledTableCell align="right">Lead Manager</StyledTableCell>
    //             <StyledTableCell align="right">Market Maker</StyledTableCell> */}
    //             <StyledTableCell align="right">
    //               Issue Price (Rs)
    //             </StyledTableCell>
    //             <StyledTableCell align="right">
    //               Issue Size (Rs Cr.)
    //             </StyledTableCell>
    //             <StyledTableCell align="right">Lot Size</StyledTableCell>
    //             <StyledTableCell align="right"> Exchange</StyledTableCell>
    //         </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {recentlyListedIPOs.slice(0, 8).map((row) => (
    //         <StyledTableRow key={row[0]}>
    //           <StyledTableCell component="th" scope="row"  onMouseEnter={(e) => e.target.style.color = '#ff7847'}
    //     onMouseLeave={(e) => e.target.style.color = '#666'} style={{fontWeight: "bold", cursor: 'pointer',color:"#666" }} onClick={() => handleIPOClick(row[1])} >
            
    //         <div style={{ display: "flex", alignItems: "center" }}>
    //                     <img src={`../a-z_icons/icons8-${row[1].charAt(0).toLowerCase()}-64.png`} alt="Image Description" style={{ marginRight: "0px" ,width:"50px"}} />
    //                     {row[1]}
    //                 </div>
        
    //           </StyledTableCell>
    //           <StyledTableCell align="right">{formatDate(row[3])}</StyledTableCell>
    //           <StyledTableCell align="right">{formatDate(row[4])}</StyledTableCell>
    //           <StyledTableCell align="right">{formatDate(row[5])}</StyledTableCell>
    //           <StyledTableCell align="right">{row[6]}</StyledTableCell>
    //           <StyledTableCell align="right">{row[7]}</StyledTableCell>
    //           <StyledTableCell align="right">{row[8]}</StyledTableCell>
    //           <StyledTableCell align="right">{row[9]}</StyledTableCell>
    //         </StyledTableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
    ) : (
      <p style={{ textAlign: 'left',marginBottom:"30px",marginLeft:"20px"}}>No Recently Listed IPO available.</p>
  )}
  {/* </div> */}

  <div style={{textAlign:"justify",margin:"30px 0px 25px 0px",padding:"30px",backgroundColor:"#eefaff",border:"1px solid #6bb7d3",borderRadius:"10px"}}><strong>“</strong>IPOtank is your premier destination for IPO investments in India. Stay informed with our comprehensive IPO Dashboard, featuring the latest offerings for 2024. Explore and invest in the most recent IPOs today!<strong>”</strong></div>
  <FaqComponent  type="Home" />
          </>):
          (<><div ref={currentIpoRef} className="loading-container">
          <div className="spinner"></div>
          <div>Fetching data, please hold on...</div>
        </div>
        <div style={{textAlign:"justify",margin:"30px 0px 25px 0px",padding:"30px",backgroundColor:"#eefaff",border:"1px solid #6bb7d3",borderRadius:"10px"}}><strong>“</strong>IPOtank is your premier destination for IPO investments in India. Stay informed with our comprehensive IPO Dashboard, featuring the latest offerings for 2024. Explore and invest in the most recent IPOs today!<strong>”</strong></div>
      <FaqComponent  type="Home" /></>)}
  </div>
  <br/>
  <br/>
    {/* </div> */}
      </div>
  <ConfirmDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
            />
      </>
  );
}
